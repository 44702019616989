/* ------------------------------------------ */
/* Header */
/* ------------------------------------------ */

/* Banner (hele raden / BG): Mobile */
.header_banner_mobile {

    /* ------------------------------------------ */
    /* Må kanskje adde ekstra marg top til page_core/elementene under header */
    /* - Kanskje dynamisk mtp. dropdown som også kan være sticky  */
    /* ------------------------------------------ */

    /* Display */
    position:               fixed;
    top:                    0;
    left:                   0;
    display:                flex;
    flex-direction:         row;
    justify-content:        space-around; /* space-between; flex-end */
    align-items:            center;
    width:                  100%;
    height:                 var(--lay_header_yy); /* path_fixed ish :: 6rem */
    z-index:                var(--z_index_header_mobile);
    /* animation: 500ms ease-in-out 0s normal none 1 running fadeInDown; */

    /* Frame */
    padding-top:            0px;
    padding-bottom:         0px;

    /* Color */
    background-color:       transparent; /* ori < var(--farge_header_bg) */
    opacity:                0.96; /* var(--opacity_header) */

    /* Non sticky
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: var(--lay_header_yy);

    background-color: var(--farge_header_bg); */
}
.header_banner_mobile_left {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;
    height:                 100%;

    /* Frame */
    padding-left:           var(--lay_space_x1);

    /* Color */
    background-color:       transparent;
}
.header_banner_mobile_center {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        center;
    align-items:            center;
    width:                  100%;
    height:                 100%;

    /* Color */
    background-color:       transparent
}
.header_banner_mobile_right {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-end;
    align-items:            center;
    width:                  100%;
    height:                 100%;

    /* Frame */
    padding-right:          var(--lay_space_x1);

    /* Color */
    background-color:       transparent
}
/* Banner (hele raden / BG): Desktop */
.header_banner_desktop {

    /* Display */
    position:               fixed;
    top:                    0;
    left:                   0;
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            stretch; /* stretch */
    width:                  100%; /* 100% hvis wrapper med right-element */
    height:                 var(--lay_header_yy);
    /* gap: 1rem; */

    /* Color */
    background-color:       var(--farge_header_bg); /* Debug: darkblue */
    opacity:                0.96; /* var(--opacity_header) */

    /* Non sticky
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: var(--lay_header_yy);

    background-color: var(--farge_header_bg); */
}

/* Dropdown: Mobile > Debug 1 */
.header_dropdown_mobile_hele_fixed {

    /* Display */
    position:               fixed;

    /* Hvis scorlling > Mute denne */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    height:                 auto;
    width:                  100%;
    overflow-y:             hidden; /* ori > virker ikke: scrollende side under */

    /* Hvis scroolling
    overflow-y: scroll;
    scroll-behavior: smooth; */

    /* Frame */
    margin-top:             var(--lay_header_yy); /* sticky extra */
    padding-left:           var(--lay_space_x1);
    padding-right:          var(--lay_space_x1);
    /* border-bottom: var(--lay_border_main) solid var(--farge_border_main); */

    /* Color */
    background-color:       var(--farge_header_bg); /* DEBUG > darkgreen, var(--farge_header_bg) */
    opacity:                0.96; /* var(--opacity_header) */

    /* Non sticky
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;

    padding-left: var(--lay_space_x1);
    padding-right: var(--lay_space_x1);

    background-color: var(--farge_header_bg); */
}
/* Dropdown: Mobile > Debug 2 */
.header_dropdown_mobile_hele_fixed_limit {

    /* Display */
    position:               fixed;

    /* Hvis scorlling > Mute denne */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    height:                 auto;
    width:                  100%;
    overflow-y:             hidden; /* ori > virker ikke: scrollende side under */

    /* Hvis scroolling
    overflow-y: scroll;
    scroll-behavior: smooth; */

    /* Frame */
    margin-top:             var(--lay_header_yy); /* sticky extra */
    padding-left:           var(--lay_space_x1);
    padding-right:          var(--lay_space_x1);
    /* border-bottom: var(--lay_border_main) solid var(--farge_border_main); */

    /* Color */
    background-color:       var(--farge_header_bg); /* DEBUG > orange, var(--farge_header_bg) */
    opacity:                0.96; /* var(--opacity_header) */

    /* Non sticky
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;

    padding-left: var(--lay_space_x1);
    padding-right: var(--lay_space_x1);

    background-color: var(--farge_header_bg); */
}
/* Dropdown: Mobile > Debug 3 */
.header_dropdown_mobile_hele_dynamisk {

    /* Display
    position:               fixed; */

    /* Hvis scorlling > Mute denne */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    height:                 auto;
    width:                  100%;
    overflow-y:             hidden; /* ori > virker ikke: scrollende side under */

    /* Hvis scroolling
    overflow-y: scroll;
    scroll-behavior: smooth; */

    /* Frame */
    margin-top:             var(--lay_header_yy); /* sticky extra */
    padding-left:           var(--lay_space_x1);
    padding-right:          var(--lay_space_x1);
    /* border-bottom: var(--lay_border_main) solid var(--farge_border_main); */

    /* Color */
    background-color:       var(--farge_header_bg); /* DEBUG > darkblue, var(--farge_header_bg) */
    opacity:                0.96; /* var(--opacity_header) */

    /* Non sticky
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;

    padding-left: var(--lay_space_x1);
    padding-right: var(--lay_space_x1);

    background-color: var(--farge_header_bg); */
}
/* Dropdown: Mobile > Debug 4 */
.header_dropdown_mobile_hele_dynamisk_none {

    /* Display
    */
    position:               fixed;

    /* Hvis scorlling > Mute denne */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    height:                 auto;
    width:                  100%;
    overflow-y:             hidden; /* ori > virker ikke: scrollende side under */

    /* Hvis scroolling
    overflow-y: scroll;
    scroll-behavior: smooth; */

    /* Frame */
    margin-top:             var(--lay_header_yy); /* sticky extra */
    padding-left:           var(--lay_space_x1);
    padding-right:          var(--lay_space_x1);
    /* border-bottom: var(--lay_border_main) solid var(--farge_border_main); */

    /* Color */
    background-color:       var(--farge_header_bg); /* DEBUG > red, var(--farge_header_bg) */
    opacity:                0.96; /* var(--opacity_header) */

    /* Non sticky
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;

    padding-left: var(--lay_space_x1);
    padding-right: var(--lay_space_x1);

    background-color: var(--farge_header_bg); */
}
/* Dropdown: Desktop */
.header_desktop_dropdown_ch {

    /*
    display: flex;
    flex-direction: column; */
    position:               absolute;
    top:                    var(--lay_header_dropdown_top); /* Uten border: 2.5rem */

    /* Frame
    padding-bottom: var(--lay_space_x05);
    padding-right: var(--lay_space_x1c5);
    padding-left: 0; */

    padding:                var(--lay_dropdown_padding);
    border-radius:          var(--lay_border_radius_dropdown);
    border:                 var(--lay_space_1px) solid var(--farge_border_main);

    /* Color */
    background-color:       var(--farge_dropdown_bg); /* red */
    opacity:                0.96; /* var(--opacity_header) */
}

/* Item: Mobile (ch, custom par) */
.header_item_mobile_ch_custom {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    height:                 var(--lay_nav_yy);
    min-height:             var(--lay_nav_yy);
    max-height:             var(--lay_nav_yy);

    /* Color */
    background-color:       transparent; /* Debug: darkblue */

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.header_item_mobile_ch_custom:hover {
    /* Color */
    background-color:       transparent;
    color:                  var(--farge_beige_2_hover);
}
.header_item_mobile_par_pointer_wrapper {

    /* Note:
       - Kun slik at items kan klikkes (ingen BG farge her)
    */

    /* Display */
    position:               relative;
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    height:                 0; /* Overstyrt > header.js */
    gap:                    0;

    /* Color */
    background-color:       transparent; /* Debug: header.js */

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

/* Item: Desktop (Logo, custom par) */
.header_item_desktop_par_default {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    /* flex-shrink: 0; */

    /* Color */
    background-color:       transparent; /* Debug: darkolivegreen */

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.header_item_desktop_par_default:hover {

    /* Color */
    background-color:       transparent;
    color:                  transparent; /* var(--farge_beige_2_hover); */
}
/* Item: Desktop (Par, ch, ikke-custom) */
.header_item_desktop_par_ch {

    /* - Note: margin-top: 1.5 rem pga. height: fit-content; */
    /* sender den til topps (height: auto funker ikke) */

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  fit-content;
    max-width:              max-content;
    overflow:               hidden;

    /* Frame */
    /* margin-top: var(--lay_space_x1c5); */
    margin-left:            var(--lay_space_x1); /* 0 */

    /* Color */
    background-color:       transparent;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

/* Par wrapper: Mobile */
.header_item_mobile_par_wrapper {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;
    height:                 var(--lay_nav_yy);
    min-height:             var(--lay_nav_yy);
    max-height:             var(--lay_nav_yy);

    /* Color */
    background-color:       transparent;
}
/* Collapsed > Hover over txt > Hover over icon > Mobile */
.header_item_mobile_par_wrapper:hover .icon_arrow_collapsed_beige_2_norm_48_12 {

    /* ORI: Google: arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg */
    /* Note: Samme størrelse som medium */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_beige_2_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}
/* Expanded > Hover over txt > Hover over icon > Mobile */
.header_item_mobile_par_wrapper:hover .icon_arrow_expanded_beige_2_norm_48_20 {

    /*
    Note:
    - ORI: Google: expand_more_FILL0_wght400_GRAD0_opsz48.svg
    - Ekstra: Marg
    - Samme størrelse som medium
    - DEV: Sjekk om rounded?
    */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/arrow_expanded_beige_2_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_icon_wrapper_marg_xp);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

/* Par wrapper: Desktop */
.header_item_desktop_par_wrapper {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    /* width: 100%;  auto */
    min-height:             var(--lay_space_x1c5);
    max-height:             var(--lay_space_x1c5);
    flex-shrink:            0;

    /* Color */
    background-color:       transparent; /* Debug: darkslategray */
}
/* Collapsed > Hover over txt > Hover over icon > Desktop */
.header_item_desktop_par_wrapper:hover .icon_arrow_collapsed_beige_2_norm_48_12 {

    /* ORI > Google > arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg */
    /* Note > Samme størrelse som medium */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_beige_2_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}
/* Expanded > Hover over txt -> Hover over icon: Desktop */
.header_item_desktop_par_wrapper:hover .icon_arrow_expanded_beige_2_norm_48_20 {

    /*
    - Note:
      - ORI > Google: expand_more_FILL0_wght400_GRAD0_opsz48.svg
      - Ekstra > Marg
      - Samme størrelse som medium
    - DEV >  Sjekk om rounded?
    */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/arrow_expanded_beige_2_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_icon_wrapper_marg_xp);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

/* Mobil */
@media (max-width: 700px) {

    .header_banner_mobile {

        /* Display */
        display:            flex;
    }

    .header_banner_desktop {

        /* Display */
        display:            none;
    }

    .header_dropdown_mobile_hele {

        /* Display */
        display:            flex;
    }

    .header_item_desktop_par_default {

        /* Frame */
        padding-top:        var(--lay_space_x05);
        padding-bottom:     var(--lay_space_x05);

        /* Color */
        background-color:   transparent;
    }
}

/* Desktop */
@media (min-width: 701px) {

    .header_banner_mobile {

        /* Display */
        display:            none;
    }

    .header_banner_desktop {

        /* Display */
        display:            flex;
    }

    .header_dropdown_mobile_hele {

        /* Display */
        display:            none;
    }

    .header_item_desktop_par_default {

        /* Frame */
        margin-left:        var(--lay_space_x1);
    }

    .strek_header {

        /* Display */
        display:            none;
    }
}


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
