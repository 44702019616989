/* ------------------------------------------ */
/* Debug */
/* ------------------------------------------ */

.tekst {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_2);
}

.txt_debug {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  peru;
}


/* ------------------------------------------ */
/* Header */
/* ------------------------------------------ */

.txt_norm_beige_2_link_header {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_2);
}
.txt_norm_beige_2_link_header:hover {
    /* Color */
    color:                  var(--farge_beige_2_hover);
}

/* Mobile */
.txt_norm_beige_2_link_marg_L_header_par_mobile {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Frame */
    margin-left:            var(--lay_header_super_marg_L_xx); /* Tweak */

    /* Color */
    color:                  var(--farge_beige_2); /* white */
}
.txt_norm_beige_2_link_marg_L_header_par_mobile:hover {
    /* Color */
    color:                  var(--farge_beige_2_hover);
}

.txt_norm_beige_2_link_marg_L_header_par_mobile_no_arrow {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Frame */
    margin-left:            0; /* Tweak */

    /* Color */
    color:                  var(--farge_beige_2); /* Debug: blue */
}
.txt_norm_beige_2_link_marg_L_header_par_mobile_no_arrow:hover {
    /* Color */
    color:                  var(--farge_beige_2_hover);
}

.txt_norm_beige_2_link_marg_LT_header_ch_mobile {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Frame */
    /* margin-top: 10px; */ /* Tweak */
    margin-left:            26px; /* Tweak */

    /* Color */
    color:                  var(--farge_beige_2);
}
.txt_norm_beige_2_link_marg_LT_header_ch_mobile:hover {
    /* Color */
    color:                  var(--farge_beige_2_hover);
}

/* Desktop */
.txt_norm_beige_2_link_marg_header_desktop {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Frame
    margin-left: var(--lay_header_super_marg_L_xx); */

    /* Color */
    color:                  var(--farge_beige_2);
}
.txt_norm_beige_2_link_marg_header_desktop:hover {
    /* Color */
    color:                  var(--farge_beige_2_hover);
}

/* txt_norm_beige_2_link_marg_LT_header_desktop */
.txt_norm_beige_2_link_marg_LT_header_desktop {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Frame
    margin-top: var(--lay_space_x05);
    margin-left: var(--lay_space_x05); */
    margin-top:             var(--lay_space_x05);

    /* Color */
    color:                  var(--farge_beige_2);
}
.txt_norm_beige_2_link_marg_LT_header_desktop:nth-of-type(1) {
    /* Frame */
    margin-top:             0;
}

.txt_norm_beige_2_link_marg_LT_header_desktop:hover {
    /* Color */
    color:                  var(--farge_beige_2_hover);
}


/* ------------------------------------------ */
/* Term */
/* ------------------------------------------ */

.txt_terminal {
    /* Txt */
    font-family:            var(--lay_font_term);
    font-size:              var(--lay_font_term_size);
    font-weight:            var(--lay_font_term_weight);
    text-decoration:        none;
    width:                  auto;

    /* Text */
    white-space:            pre;

    /* Color */
    color:                  var(--farge_beige_1); /* var(--farge_grey_6) */
    background:             transparent;
}


/* ------------------------------------------ */
/* Mikro */
/* ------------------------------------------ */

.txt_mikro_grey {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_mikro_size);
    font-weight:            var(--lay_font_mikro_weight);
    text-decoration:        none;

    /* Color */
    background-color: transparent;
    color:                  var(--farge_grey_6);
}


/* ------------------------------------------ */
/* Mini */
/* ------------------------------------------ */

.txt_mini_grey {
    /* DEV: Ubrukt kanskje */
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_mini_size);
    font-weight:            var(--lay_font_mini_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_grey_6);
}
.txt_mini_grey_link {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_mini_size);
    font-weight:            var(--lay_font_mini_weight);
    text-decoration:        none;

    /* Color */
    background-color: transparent;
    color:                  var(--farge_grey_6);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Liten */
/* ------------------------------------------ */

.txt_liten_white {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_white_1);
}

.txt_liten_white_hidden {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  transparent;
}

.txt_liten_beige_1 {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_1);
}
.txt_liten_beige_1:hover {
    /*
        Note: Hover for kode_editor > file_liste
        - Sjekk om andre
    */
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_2);
}

.txt_liten_beige_1_bold {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight_bold);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_1);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.txt_liten_beige_1_bold:hover {
    /*
        Note: Hover for kode_editor > file_liste
        - Sjekk om andre
    */
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight_bold);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_2);

}

.txt_liten_beige_1_wrapless {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;
    flex-shrink:            0;

    /* min-width: 4rem; */

    /* Color */
    color:                  var(--farge_beige_1);
}
.txt_liten_beige_1_wrapless_edit {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;
    flex-shrink:            0;
    width:                  100%;

    /* min-width: 4rem; */

    /* Color */
    color:                  var(--farge_beige_1);
}

.txt_liten_beige_2_wrapless_ALTERNATIV:hover {
    /* Ubrukt - slett ??? */

    /* DEV > Merk at denne er beige_2, mens den over er beige_1 */

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;
    flex-shrink:            0;

    /* Color */
    color:                  var(--farge_beige_2);
}

.txt_liten_grey {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent; /* darkviolet */
    color:                  var(--farge_grey_6);
}

.txt_liten_grey_link {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Frame */

    /* Color */
    color:                  var(--farge_grey_6);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.txt_liten_grey_link:hover {
    /* Color */
    color:                  var(--farge_beige_2_hover);
}

.txt_liten_grey_bold {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight_bold);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_grey_6);
}

.txt_liten_grey_dark {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent; /* darkviolet */
    color:                  var(--farge_grey_5);
}

.txt_liten_link {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_link_norm);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.txt_liten_link:hover {
    /* Txt */
    text-decoration:        underline;
}

.txt_liten_green {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_green_1_hover);
}

.txt_liten_yellow {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_yellow_1);
}

.txt_liten_orange {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_orange_1);
}

.txt_liten_red {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_red_1);
}


/* ------------------------------------------ */
/* Norm */
/* ------------------------------------------ */

.txt_norm_grey {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_grey_6);
}
.txt_norm_grey_quest_tittel {

    /* Frame */
    margin-left:            var(--lay_space_xn05);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_grey_6);
}

.txt_norm_beige_2 {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_2);
}
.txt_norm_beige_2_hoverable {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_2);
}
.txt_norm_beige_2_hoverable:hover {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_2_hover);
}

.txt_norm_white {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_white_1);
}

.txt_norm_link {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color  */
    color:                  var(--farge_link_norm);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.txt_norm_link:hover {
    /* Txt */
    text-decoration:        underline;
}

.txt_norm_red {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_red_2);
}


/* ------------------------------------------ */
/* Felt */
/* ------------------------------------------ */

.txt_modal_beige_1 {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_modal_size);
    font-weight:            var(--lay_font_modal_weight_bold);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_1);
}


/* ------------------------------------------ */
/* Medium */
/* ------------------------------------------ */

.txt_medium_grey {
    /* Frame */
    margin-bottom:          var(--lay_space_x05);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_grey_6);
}

.txt_medium_grey_bold {
    /* Note: */

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight_bold);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_grey_6);
    background-color:       transparent;
}

.txt_medium_beige_1 {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_1);
}

.txt_medium_beige_3_line_height_ori {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;
    line-height:            var(--lay_space_x1c75);

    /* Color */
    color:                  var(--farge_beige_3_landing); /* --farge_beige_1 */
}

.txt_medium_beige_3_line_height {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size_landing); /* lay_font_medium_size */
    font-weight:            var(--lay_font_medium_weight_landing);
    text-decoration:        none;
    line-height:            var(--lay_space_x1c75);

    word-wrap: break-word;

    /* Color */
    color:                  var(--farge_beige_3_landing); /* --farge_beige_1 */
}

.txt_medium_link {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_link_norm);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.txt_medium_link:hover {
    /* Txt */
    text-decoration:        underline;
    /* Color: Hvis skifte farge når hover */
    /* color: var(--farge_blue_1_hover); */
}

.txt_medium_link_landing {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size_landing);
    font-weight:            var(--lay_font_medium_weight_landing);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_link_norm);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.txt_medium_link_landing:hover {
    /* Txt */
    text-decoration:        underline;
    /* Color: Hvis skifte farge når hover */
    /* color: var(--farge_blue_1_hover); */
}


/* ------------------------------------------ */
/* Stor */
/* ------------------------------------------ */

.txt_stor_beige_1 {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_stor_size);
    font-weight:            var(--lay_font_stor_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_beige_1);
}

.txt_stor_link {
    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_stor_size);
    font-weight:            var(--lay_font_stor_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_link_norm);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.txt_stor_link:hover {
    /* Txt */
    text-decoration:        underline;
}


/* ------------------------------------------ */
/* Spill */
/* ------------------------------------------ */

.txt_spill_uttrykk_stor {

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_uttrykk_giga_size);
    font-weight:            var(--lay_font_stor_weight);
    text-decoration:        none;

    /* Frame */
    margin-left:            auto;

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_uttrykk); /* var(--farge_beige_1) */
}

.txt_spill_oppg_main {

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_uttrykk); /* var(--farge_beige_1) */

}
.txt_spill_oppg_main_tab {

    /* Ubrukt */

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

     /* Frame > Tab in uttrykk
    margin-left:            3rem; */

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_uttrykk); /* var(--farge_beige_1) */

}
.txt_spill_oppg_main_riktig {

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

    /* Frame
    margin-top:             -0.5rem; */

    /* Quickfix på marg-bug */

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_uttrykk_riktig); /* var(--farge_beige_1) */

}
.txt_spill_oppg_main_feil {

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_uttrykk_feil); /* var(--farge_beige_1) */

}


/* ------------------------------------------ */
/* Gradient */
/* ------------------------------------------ */

.txt_grad_medium {
    /* Txt */
    font-family:                var(--lay_font);
    font-size:                  var(--lay_font_medium_size);
    font-weight:                var(--lay_font_medium_weight);
    text-decoration:            none;

    /* Color */
    background-color:           transparent;
    color:                      var(--farge_beige_1);

    /* Gradient */
    animation:                  flow 30s ease-in-out infinite;
    background:                 linear-gradient(-60deg, var(--farge_grad_1), var(--farge_grad_2));
    background-size:            200%; /* ORI: 300% */
    background-clip:            text;
    -webkit-background-clip:    text;
    -webkit-text-fill-color:    transparent;
}

.txt_stor_grad {
    /* Txt */
    font-family:                var(--lay_font);
    font-size:                  var(--lay_font_stor_size);
    font-weight:                var(--lay_font_stor_weight);
    text-decoration:            none;

    /* Color */
    background-color:           transparent;
    color:                      var(--farge_beige_1);

    /* Gradient */
    animation:                  flow 30s ease-in-out infinite;
    background:                 linear-gradient(-60deg, var(--farge_grad_1), var(--farge_grad_2));
    background-size:            200%; /* ORI: 300% */
    background-clip:            text;
    -webkit-background-clip:    text;
    -webkit-text-fill-color:    transparent;
}

.txt_mega_1_ORI {
    /* Txt */
    font-family:                var(--lay_font);
    font-size:                  var(--lay_font_mega_1_size);
    font-weight:                var(--lay_font_mega_1_weight);
    text-decoration:            none;

    /* Color */
    background-color:           transparent;
    color:                      var(--farge_white_1);

}
.txt_mega_2_ORI {
    /* Txt */
    font-family:                var(--lay_font);
    font-size:                  var(--lay_font_mega_2_size);
    font-weight:                var(--lay_font_mega_2_weight);
    text-decoration:            none;

    /* Color */
    background-color:           transparent;
    color:                      var(--farge_white_1);

}

/*
@font-face {
	font-family:    'MyCustomFont';
	src:            url('/fonts/my-font.woff2') format('woff2'),
	    url('/fonts/my-font.woff') format('woff'),
	    url("/frontend/src/static/font/distropiax-font/Distropiax-WpYMA.ttf") format('truetype'),
	    url('/fonts/my-font.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}
*/

@font-face {
    font-family:    "matasy_font";

    /* Husk > format('opentype') */

    /*

        // 3D
        "../fonts/cube-font/Cube-rxaL.ttf" - 2rem - Kul, men litt rar / barnslig
        "../fonts/d3-cubism-font/D3Cubism-VGx6.ttf" - 4rem - For stygg

        // Action
        *** --- "../fonts/suggested/Suggested3D.ttf" - Kul, veldig Doom
        "../fonts/viking-squad-font/VikingSquad-WpW8E.otf"

        // Art Nouveau
        "../fonts/art-nouveau-font/ArtNouveauByMartaVanEck-159Z.ttf" - For rar

        // Eastern
        "../fonts/cartius-font/CartiusDemoRegular-rvy5A.ttf" - 5rem - Straight, for kjedelig

        // Fashion
        *** --- "../fonts/prida-em-cooper-font/Pridaemcooper-RpyMV.ttf" -
        "../fonts/kyoku-outline-font/KyokuOutlineFontRegular-MAdAP.otf" - Ganske classy, kanskje litt for clean
        "../fonts/nesatho-beryl-font/Nesathoberyl-BW9j3.ttf" - For Dick Tracy
        "../fonts/fashion-norsa-font/FashionNorsaRegular-ovovA.otf" - For kjedelig
        "../fonts/gatsby-gilmour-font/GatsbyGilmourRegular-xR40j.otf"

        // Futuristic
        "../fonts/aexir-font/Aexir-XG3q9.otf" - 6rem
        "../fonts/file-deletion-font/FileDeletion-yw6M5.ttf" - 6rem
        "../fonts/iron-cobra-font/IronCobra-MVnGv.ttf" - 5rem
        "../fonts/rigsport-font/Rigsport-3laR6.otf" - Kul, men litt for rar

        // Gothic
        "../fonts/kingthings-spikeless-font/KingthingsSpikeless-lKPZ.ttf" - 10rem
        "../fonts/distropiax-font/Distropiax-WpYMA.ttf" - 10rem - Mangler serifs

        // Gothic - Outlined
        "../fonts/bavarian-outline-font/BavarianOutlinePersonalOutline-axnVa.otf" - For Kaptein Sabeltann
        "../fonts/solipsistic/Solipsistic.otf" - For kjedelig

        // Japan - 3D
        "../fonts/kamikaze/Kamikaze3DGradient.ttf" - For asiatisk

        // Metal
        *** --- "../fonts/maskneyes-font/MaskneyesDemo-qZPar.otf" - Interssant, klore-merker
        "../fonts/berosong/Berosong-Regular.ttf" - For ulik og stygg
        "../fonts/roomach/Roomach-Regular.ttf" - For ulik og stygg
        "../fonts/kahless-font/Kahless-X3oZG.ttf" - For stygg
        "../fonts/metal-macabre-font/MetalMacabre-dnoK.ttf" - For uproff

        // Outline
        "../fonts/cs-roger-outline-font/CsRogerOutline-DOe3R.otf" - Viser ikke toppen

        // University
        "../fonts/sporting-outline-font/SportingOutline-x3e85.ttf" - For cheesy, straight og kjedelig

        // Viking
        "../fonts/vidir-futhark-font/VidirT2-nLWP.ttf" - Litt enkel
        "../fonts/heorot-font/HeorotExpanded-0RLz.ttf" - Alt for barnslig
        "../fonts/Woodwarrior/Woodwarrior-Light.otf" - Litt kjipe bokstaver

        // Viking - Outlined
        *** --- "../fonts/odins-spear-font/OdinsSpearRaggedHollow-Pq8B.ttf" - 5rem - Veldig kul, kun outline
        *** --- "../fonts/floki/floki-Hard.ttf" - Kul, men for bold og straight, endre noen bokstaver
    */

    /*
    night - lag download-knapp

    "../fonts/odins-spear-font/OdinsSpearRaggedHollow-Pq8B.ttf"
    "../fonts/suggested/Suggested3D.ttf"
    "../fonts/floki/floki-Hard.ttf"
    "../fonts/prida-em-cooper-font/Pridaemcooper-RpyMV.ttf"
    "../fonts/maskneyes-font/MaskneyesDemo-qZPar.otf"
    */

	src:            url("../fonts/odins-spear-font/OdinsSpearRaggedHollow-Pq8B.ttf");

}

.txt_mega_1 {

    /* Txt - 'MyCustomFont', sans-serif;*/
    font-family:                "matasy_font"; /* Fallback to sans-serif */
    font-size:                  5rem;

    /*
    font-style:                 italic;
    */

    /* Image Background */
    background:                 url("../img/bg/_font_bg.png");
    background-size:            cover;
    -webkit-background-clip:    text;               /* Necessary for the image to clip to the text */
    background-clip:            text;               /* Clip the background to the text */
    color:                      transparent;        /* Make the text color transparent so the image shows through */

}
.txt_mega_2 {

    /* Txt - 'MyCustomFont', sans-serif;*/
    font-family:                "matasy_font"; /* Fallback to sans-serif */
    font-size:                  2.5rem;

    /*
    font-style:                 italic;
    */

    /* Image Background */
    background:                 url("../img/bg/_font_bg.png");
    background-size:            cover;
    -webkit-background-clip:    text;               /* Necessary for the image to clip to the text */
    background-clip:            text;               /* Clip the background to the text */
    color:                      transparent;        /* Make the text color transparent so the image shows through */

}

.txt_liten_NEW {

    /* Txt - 'MyCustomFont', sans-serif;*/
    font-family:                "matasy_font"; /* Fallback to sans-serif */
    font-size:                  2rem;

    /* Txt
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_liten_size);
    font-weight:            var(--lay_font_liten_weight);
    text-decoration:        none;
    */

    /* Color */
    background-color:       transparent; /* darkviolet */
    color:                  var(--farge_grey_6);

    /*
    font-style:                 italic;
    */

    /* Image Background */
    background:                 url("../img/bg/_font_bg.png");
    background-size:            cover;
    -webkit-background-clip:    text;               /* Necessary for the image to clip to the text */
    background-clip:            text;               /* Clip the background to the text */
    color:                      transparent;        /* Make the text color transparent so the image shows through */


    /* Color */
    background-color:       transparent; /* darkviolet */
    color:                  var(--farge_grey_6);

}

.txt_card_landing {

    /* Txt */
    font-family:                var(--lay_font);
    font-size:                  var(--lay_font_card_landing_size);
    font-weight:                var(--lay_font_card_weight);
    text-decoration:            none;

    /* Color */
    background-color: transparent;
    color:                      var(--farge_white_1);
}
.txt_card_landing_grad {

    /* Txt */
    font-family:                var(--lay_font);
    font-size:                  var(--lay_font_card_landing_size);
    font-weight:                var(--lay_font_card_weight);
    text-decoration:            none;

    /* Color */
    background-color: transparent;
    color:                      var(--farge_beige_1);

    /* Gradient */
    animation:                  flow 30s ease-in-out infinite;
    background:                 linear-gradient(-60deg, var(--farge_grad_1), var(--farge_grad_2));
    background-size:            200%;
    background-clip:            text;
    -webkit-background-clip:    text;
    -webkit-text-fill-color:    transparent;
}

/* ------------------------------------------ */
/* Reponsive > Søkeord > CSS_Media */
/* ------------------------------------------ */

.txt_mega_resp {

    /* DEV > Foreløpig ikke grad */

    /* Txt */
    font-family:                var(--lay_font);
    font-size:                  var(--lay_font_mega_1_size);
    font-weight:                var(--lay_font_mega_1_weight);
    text-decoration:            none;

    /* Color */
    background-color:           transparent;
    color:                      var(--farge_beige_1); /* Responsive */

    /* Gradient
    animation: flow 30s ease-in-out infinite;
    background: linear-gradient(-60deg, var(--farge_grad_1), var(--farge_grad_2));
    background-size: 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */

}

/* Mobile 2 */
@media (min-width: 0px) {

    .txt_mega_resp {

        /* Txt */
        font-size:                  var(--lay_font_mega_1_size_card);
        font-weight:                var(--lay_font_mega_1_weight_card);

        /* Color > Debug */
        color:                      var(--farge_beige_1); /* red */

    }
}

/* Mobile 1, Desktop */
@media (min-width: 530px) {

    .txt_mega_resp {

        /* Txt */
        font-size:                  var(--lay_font_mega_1_size);
        font-weight:                var(--lay_font_mega_1_weight);

        /* Color > Debug */
        color:                      var(--farge_beige_1); /* red */

    }

}


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
