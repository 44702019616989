/* ------------------------------------------ */
/* Footer */
/* ------------------------------------------ */

.footer_mobile {

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_footer_mobile_yy);
    width:              100%;
    gap:                var(--lay_space_x1);
    overflow:           hidden;

    /* Color */
    background-color:   transparent;
}

.footer_mobile_NEW {

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_footer_mobile_yy);
    width:              100%;

    /* gap under streken */
    gap:                var(--lay_space_x1);
    overflow:           hidden;

    /* Color */
    background-color:   transparent;
}

.footer_link_wrapper {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             auto;
    width:              auto;
    gap:                var(--lay_space_x1);
    overflow:           hidden;

    /* Color */
    background-color:   transparent;
}

.footer_logo_copyright {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             auto;
    width:              auto;
    gap:                var(--lay_space_x1);
    overflow:           hidden;

    /* Color */
    background-color:   transparent;
}


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
