/* ------------------------------------------ */
/* Properties */
/* ------------------------------------------ */

.display_none {

    /* Display */
    display:                    none;
}

.empty_wrapper_hack {

    /* Note:
        - Flere ord blir tvunget til wordwrap av flexbox
        - Paddingen blir ignorert med bl.a. width: max-content som fikser selve word-wrappen
    */

    /* Color */
    background-color:           transparent;
}

/* ------------------------------------------ */
/* App */
/* ------------------------------------------ */

.flex_app_hele {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                stretch; /* Nødvendig? sjekk */
    width:                      100%;

    /* min-height:                 100vh; */

    overflow:                   hidden;

    /* Color */
    background-color:           var(--farge_black_2);
}

.flex_app_hele_NEW {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                stretch; /* Nødvendig? sjekk */
    width:                      100%;

    overflow:                   hidden;

    /* Color */
    background-color:           var(--farge_black_2);
}


.flex_app_body {
    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    overflow:                   visible; /* hidden ??? */
    min-height:                 100vh;
    width:                      100%;

    /* Frame */
    margin-top:                 0; /* header + path, 6rem */

    /* Color */
    background-color:           var(--farge_app_body);

    /* Non sticky
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    padding: var(--lay_space_x1);

    background-color: transparent; */
}


.flex_app_body_NEW {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    overflow:                   visible; /* hidden ??? */

    width:                      100%;

    /* Frame */
    margin-top:                 0; /* header + path, 6rem */

    /* Color */
    background-color:           var(--farge_app_body);

    /* Non sticky
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    padding: var(--lay_space_x1);

    background-color: transparent; */
}


/* ------------------------------------------ */
/* Page */
/* ------------------------------------------ */

.flex_page_core {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%; /* min-height: 100vh */
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding-top:                var(--lay_space_x1);
    padding-right:              var(--lay_space_x1);
    padding-bottom:             var(--lay_space_x1);
    padding-left:               var(--lay_space_x1);
    margin-top:                 var(--lay_header_yy);

    /* Color */
    background:                 var(--farge_page_core);
}

.flex_page_core_FOOTER {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%; /* min-height: 100vh */
    overflow:                   hidden;

    /* Frame
    padding-top:                var(--lay_space_x1);
    padding-right:              var(--lay_space_x1);
    padding-bottom:             var(--lay_space_x1);
    padding-left:               var(--lay_space_x1);
    margin-top:                 var(--lay_header_yy);
    */

    /* Color */
    background:                 var(--farge_page_core);
}


.flex_page_core_bg {

    /* Display > Min height */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%; /* min-height: 100vh */
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    margin-top:                 0; /* var(--lay_header_yy) */

    /* Color
    background: url("../img/bg/grad_blue.jpg");
    background-size: 150%; */
    background-color:           transparent;
}

.flex_page_core_bg_fade_in {

    /* Display > Min height */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;

    /*
    min-height:                 1000px;
    */

    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Ani */
    animation:                  fade_in_effect_landing_smooth var(--time_ani_fade_in_landing_page_core) linear forwards;

    /* Frame */
    margin-top:                 0; /* var(--lay_header_yy) */

    /* Color
    background: url("../img/bg/grad_blue.jpg");
    background-size: 150%;
    background-position:        0% 200%;
    background-position:        left;
    */

    background-color:           transparent;

}

.flex_page_core_bg_fade_in_NEW_ORRRRI {

    /* Display > Min height */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Ani */
    animation:                  fade_in_effect_landing_smooth var(--time_ani_fade_in_landing_page_core) linear forwards;

    /* Color */
    /* Color */
    background:
        linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), /* Transparent white overlay */
        url("../img/bg/grad_blue.jpg");
    background-size:            100%;
    background-color:           transparent;
}


.flex_page_core_bg_fade_in_NEW_ori2 {
    position: relative; /* Required for absolute positioning of child */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    overflow: hidden;
    gap: var(--lay_space_x1);

    /* Ani */
    animation: fade_in_effect_landing_smooth var(--time_ani_fade_in_landing_page_core) linear forwards;
}

.flex_page_core_bg_fade_in_NEW {
	position: relative; /* Required for absolute positioning of child */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
    
	width: 100%;
	min-height: 100vh; /* Ensure it covers the full viewport height */
	overflow: hidden;
	gap: var(--lay_space_x1);

	/* Ani */
	animation: fade_in_effect_landing_smooth var(--time_ani_fade_in_landing_page_core) linear forwards;

	/* Background
	background: url("../img/bg/grad_blue.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
    */
}

.landing_bg_img_NEW {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh; /* Ensures it covers the full viewport height */
	background: url("../img/bg_landing/landing.jpg") center/cover no-repeat;
	opacity: 0.2; /* Adjust this value */
	z-index: -1; /* Keeps it behind content */
}


.landing_bg_img_NEW {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../img/bg_landing/landing.jpg") center/cover no-repeat;
    opacity: 0.2; /* Adjust this value */
    z-index: -1; /* Keeps it behind content */
}



.flex_page_core_bg_fade_in_NEW_22_trash::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("../img/bg/grad_blue.jpg");
	background-size: 150%;
	background-position: left;
	opacity: 0.1; /* Adjust the opacity level */
	z-index: -1; /* Places background behind content */
}

.flex_page_core_bg_debug {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    min-height:                 100vh;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    margin-top:                 0; /* var(--lay_header_yy) */

    /* Color
    background: url("../img/bg/grad_blue.jpg");
    background-size: 150%; */
    background-color:           darkgray;
}

.flex_page_core_blur_DEV {

    /* Display */
    position:                   absolute;
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    min-height:                 100vh;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding-top:                var(--lay_space_x1);
    padding-left:               var(--lay_space_x1);
    padding-right:              var(--lay_space_x1);
    margin-top:                 var(--lay_header_yy);

    /* Color */
    background:                 black;
    opacity:                    0.6;
}

.flex_page_part {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    min-height:                 100vh;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Color */
    background:                 transparent;
}

.flex_page_load_dekker {

    /* Display */
    position:                   absolute;
    top:                        var(--lay_header_yy);
    left:                       0;
    height:                     100vh;
    width:                      100%;

    /* Color */
    background:                 var(--farge_black_2);
}

.flex_page_reg {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                center;
    width:                      100%;
    gap:                        var(--lay_space_x1);

    /* Frame */
    margin-top:                 0; /* Pga. sticky header og flex_app_body */

    /* Color */
    background-color:           transparent;
}


/* ------------------------------------------ */
/* Landing */
/* ------------------------------------------ */

.flex_landing_wrapper {

    /* DEV > Kan kanskje bare bruke flex_col_TL > txt wrapper issue er ikke css-relatert */

    /* Display > flex_col_TL */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto; /* PRØV: Skal være på quest, ikke de andre */
    overflow:               hidden;


    /* Frame */
    padding-left: 1rem;
    padding-right: 1rem;

    /* prøv
    padding: 1rem; */

    /* Color */
    background-color:       transparent;

}

.flex_landing_wrapper_NEW {

    /* DEV > Kan kanskje bare bruke flex_col_TL > txt wrapper issue er ikke css-relatert */

    /* Display > flex_col_TL */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto; /* PRØV: Skal være på quest, ikke de andre */
    overflow:               hidden;


    /* Frame */
    padding-left: 1rem;
    padding-right: 1rem;

    /* prøv
    padding: 1rem; */

    /* Color */
    background-color:       transparent;

}

/* WORK > DEBUGGING */
.flex_landing_card_intro {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    gap:                        var(--lay_space_x1);

    /* Word break
    word-break:                 break-all;
    */

    /* Frame */
    padding:                    var(--lay_space_x2);

    /* Color */
    background-color:           transparent; /* transparent, darkgreen */
}

/* WORK > DEBUGGING */
.flex_landing_card_lang_wrapper {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 100%; /* PRØV default: Skal være på quest, header usikkert */
    overflow:               hidden;

    /* Color */
    background-color:       transparent; /* darkmagenta */

}

/* WORK > DEBUGGING */
.flex_landing_card_lang {

    /* Display, Max width 28, 26, 24 */
    display:                    flex;
    flex-direction:             row;
    justify-content:            center;
    align-items:                center;
    width:                      100%; /* 100%, auto */
    gap:                        var(--lay_space_x1);
    max-width:                  0rem; /* Responsive */

    /* Frame */
    padding:                    var(--lay_space_x2);
    border:                     var(--lay_border_main) solid var(--farge_border_main); /* transparent var(--farge_blue_1) */
    border-radius:              var(--lay_border_radius_card); /* --lay_border_radius */

    /* Color */
    background-color:           var(--farge_grey_1); /* green */
    opacity:                    var(--opacity_landing_card);

    /* Cursor */
    cursor:                     pointer;
    pointer-events:             visible;

}

.flex_landing_card_lang_tekst_fade_in {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      auto;
    height:                     auto;
    overflow:                   hidden;
    overflow-wrap:              break-word;

    animation:                  fade_in_effect_landing var(--time_ani_fade_in_landing) linear forwards;

    word-wrap:                  break-word;

    padding:                    var(--lay_space_x2);
    border:                     var(--lay_border_main) solid transparent; /* transparent var(--farge_blue_1) */
    border-radius:              var(--lay_border_radius_card); /* --lay_border_radius */

    /* Color */
    background-color:           transparent; /* transparent */
    opacity:                    var(--opacity_landing_card);

}
.flex_landing_card_lang_tekst_fade_out {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      auto;
    height:                     auto;
    overflow: hidden;
    overflow-wrap: break-word;

    animation:                  fade_out_effect_landing var(--time_ani_fade_in_landing) linear forwards;


    /*
    white-space: nowrap;
    flex-wrap: wrap; */
    word-wrap: break-word;


    /*
    display: block;
    word-wrap:break-word;
    white-space: normal;
    */

    /*
    max-width: 30rem;
    min-width: 30rem;
    */

    /* Frame
    margin-left:                2rem;
    margin-right:               2rem; */

    padding:                    var(--lay_space_x2);
    border:                     var(--lay_border_main) solid transparent; /* transparent var(--farge_blue_1) */
    border-radius:              var(--lay_border_radius_card); /* --lay_border_radius */

    /* Color */
    /* background:                 rgba(255, 255, 255, .10); */
    /* 10% opaque */
    background-color:           transparent;
    opacity:                    var(--opacity_landing_card);

    /* Cursor
    cursor:                     pointer;
    pointer-events:             visible; */

}

/* Fade in landing */
.fade_in_landing {
    /* Ani */
    animation:                  fade_in_move_effect_landing var(--time_ani_fade_in_landing) linear forwards;
    -ms-animation:              fade_in_move_effect_landing var(--time_ani_fade_in_landing) linear forwards;
    -webkit-animation:          fade_in_move_effect_landing var(--time_ani_fade_in_landing) linear forwards;
    animation-iteration-count:  1;

    /* Color */
    opacity: 0;
}
.fade_in_landing_delay_1 {
    /* Ani */
    animation:                  fade_in_move_effect_landing_delay_1 var(--time_ani_fade_in_landing_delay_1) linear forwards;
    animation-delay:            1s;
    -ms-animation:              fade_in_move_effect_landing_delay_1 var(--time_ani_fade_in_landing_delay_1) linear forwards;
    -webkit-animation:          fade_in_move_effect_landing_delay_1 var(--time_ani_fade_in_landing_delay_1) linear forwards;
    animation-iteration-count:  1;

    /* Color */
    opacity: 0;

}
.fade_in_landing_delay_2 {
    /* Ani */
    animation:                  fade_in_move_effect_landing_delay_2 var(--time_ani_fade_in_landing_delay_2) linear forwards;
    animation-delay:            1.5s;
    -ms-animation:              fade_in_move_effect_landing_delay_2 var(--time_ani_fade_in_landing_delay_2) linear forwards;
    -webkit-animation:          fade_in_move_effect_landing_delay_2 var(--time_ani_fade_in_landing_delay_2) linear forwards;
    animation-iteration-count:  1;

    /* Color */
    opacity: 0;

}

/* -5, -4, -3, -2, -1 */
@keyframes fade_in_effect_landing {
    0%      {
        opacity: 0;
    }
    20%     {
        opacity: 0.2;
    }
    40%     {
        opacity: 0.4;
    }
    60%     {
        opacity: 0.6;
    }
    80%     {
        opacity: 0.8;
    }
    100%    {
        transform:      translate3d(0px, 0, 0);
    }
}
@keyframes fade_in_effect_landing_smooth_ori {

    0%      {
        opacity: 0.6;
        transform:      translate3d(0px, 0, 0);
        background-position: right -10px bottom;
    }
    20%     {
        opacity: 0.7;
        transform:      translate3d(0px, 0, 0);
        background-position: right -8px bottom;
    }
    40%     {
        opacity: 0.8;
        transform:      translate3d(0px, 0, 0);
        background-position: right -6px bottom;
    }
    60%     {
        opacity: 0.9;
        transform:      translate3d(0px, 0, 0);
        background-position: right -4px bottom;
    }
    80%     {
        opacity: 1;
        transform:      translate3d(0px, 0, 0);
        background-position: right -2px bottom;
    }
    100%    {
        transform:      translate3d(0px, 0, 0);
        background-position: right 0px bottom;
    }
}
@keyframes fade_in_effect_landing_smooth {
    0%      {
        opacity: 0.6;
        transform:      translate3d(0px, 0, 0);
        background-position: right 10px top;
    }
    20%     {
        opacity: 0.7;
        transform:      translate3d(0px, 0, 0);
        background-position: right 8px top;
    }
    40%     {
        opacity: 0.8;
        transform:      translate3d(0px, 0, 0);
        background-position: right 6px top;
    }
    60%     {
        opacity: 0.9;
        transform:      translate3d(0px, 0, 0);
        background-position: right 4px top;
    }
    80%     {
        opacity: 1;
        transform:      translate3d(0px, 0, 0);
        background-position: right 2px top;
    }
    100%    {
        transform:      translate3d(0px, 0, 0);
        background-position: right 0px top;
    }
}
@keyframes fade_in_move_effect_landing {
    0%      {
        opacity:        0;
        transform:      translate3d(100px, 0, 0);
    }
    20%     {
        opacity: 0.2;
        transform:      translate3d(80px, 0, 0);
    }
    40%     {
        opacity: 0.4;
        transform:      translate3d(60px, 0, 0);
    }
    60%     {
        opacity: 0.6;
        transform:      translate3d(40px, 0, 0);
    }
    80%     {
        opacity: 0.8;
        transform:      translate3d(20px, 0, 0);
    }
    100%    {
        opacity:        var(--opacity_landing_card);
        transform:      translate3d(0px, 0, 0);
    }
}
@keyframes fade_in_move_effect_landing_delay_1 {
    0%      {
        opacity:        0;
        transform:      translate3d(100px, 0, 0);
    }
    20%     {
        opacity: 0.2;
        transform:      translate3d(80px, 0, 0);
    }
    40%     {
        opacity: 0.4;
        transform:      translate3d(60px, 0, 0);
    }
    60%     {
        opacity: 0.6;
        transform:      translate3d(40px, 0, 0);
    }
    80%     {
        opacity: 0.8;
        transform:      translate3d(20px, 0, 0);
    }
    100%    {
        opacity:        var(--opacity_landing_card);
        transform:      translate3d(0px, 0, 0);
    }
}
@keyframes fade_in_move_effect_landing_delay_2 {
    0%      {
        opacity:        0;
        transform:      translate3d(100px, 0, 0);
    }
    20%     {
        opacity: 0.2;
        transform:      translate3d(80px, 0, 0);
    }
    40%     {
        opacity: 0.4;
        transform:      translate3d(60px, 0, 0);
    }
    60%     {
        opacity: 0.6;
        transform:      translate3d(40px, 0, 0);
    }
    80%     {
        opacity: 0.8;
        transform:      translate3d(20px, 0, 0);
    }
    100%    {
        opacity:        var(--opacity_landing_card);
        transform:      translate3d(0px, 0, 0);
    }
}


/* Fade out landing */
.fade_out_landing_card {

    /* Ani - animation-delay:            1s;*/
    animation:                  fade_out_effect_landing var(--time_ani_fade_in_landing) linear forwards;

    -ms-animation:              fade_out_effect_landing var(--time_ani_fade_in_landing) linear forwards;
    -webkit-animation:          fade_out_effect_landing var(--time_ani_fade_in_landing) linear forwards;
    animation-iteration-count:  1;

    /*
    animation:              fade_out_effect_landing ease 0s;
    animation-fill-mode:    forwards;
    opacity:                0;
    */

    /*
    -webkit-animation: fadeIn ease 20s;
    -moz-animation: fadeIn ease 20s;
    -o-animation: fadeIn ease 20s;
    -ms-animation: fadeIn ease 20s;
    */

}
@keyframes fade_out_effect_landing {
    0%      { opacity: var(--opacity_landing_card); }
    20%     { opacity: 0.8; }
    40%     { opacity: 0.6; }
    60%     { opacity: 0.4; }
    80%     { opacity: 0.2; }
    100%    { opacity: 0; }
}


.flex_landing_card_lang_wrapper_mobile {

    /* Display */
    display:                    flex;
    flex-direction:             column; /* row */
    justify-content:            flex-start;
    align-items:                center; /* left */
    width:                      100%;
    height:                     auto; /* PRØV: Skal være på quest, ikke de andre */
    overflow:                   hidden;
    gap:                        1rem;

    /* Color */
    background-color:           transparent;
}
.flex_landing_card_lang_wrapper_desktop {

    /* Display */
    display:                    flex;
    flex-direction:             row; /* column */
    flex-wrap:                  wrap;
    justify-content:            center; /* flex-start*/
    align-items:                center; /* left */
    width:                      100%;
    height:                     auto; /* PRØV: Skal være på quest, ikke de andre */
    overflow:                   hidden;
    gap:                        1rem;

    /* Color */
    background-color:           transparent; /* transparent */
}

.flex_landing_card_editor_kode_home {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    0; /* var(--lay_space_x2)*/

    /* Color */
    background-color:           transparent;
}


/* ------------------------------------------ */
/* Reg */
/* ------------------------------------------ */

.flex_reg_felt_box {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                center;
    width:                      var(--lay_space_x20);
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    var(--lay_space_x1);
    border:                     var(--lay_border_main) solid var(--farge_grey_2);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background:                 var(--farge_grey_1);
}

.flex_reg_trans_link_box {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      var(--lay_space_x20);
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    var(--lay_space_x1);
    border:                     var(--lay_border_main) solid var(--farge_grey_2);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background:                 transparent;
}

.flex_reg_blank_tittel_box {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                center;
    width:                      var(--lay_space_x20);
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    0;
    padding:                    var(--lay_space_x1);
    border:                     0 solid transparent;
    border-radius:              var(--lay_border_radius);

    /* Color */
    background:                 transparent;
}

/* ------------------------------------------ */
/* Alert */
/* ------------------------------------------ */

.flex_alert_green {

    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            space-between;
    align-items:                center;
    height:                     var(--lay_space_x3);
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    var(--lay_space_x1);
    border:                     var(--lay_border_main) solid var(--farge_alert_success_border);
    border-radius:              var(--lay_border_radius);

    /* Color var(--farge_grey_1) */
    background:                 var(--farge_alert_success_bg);
}
.flex_alert_green_reg {

    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            space-between;
    align-items:                center;
    height:                     var(--lay_space_x3);
    width:                      var(--lay_space_x20);
    overflow:                   hidden;

    /* Frame */
    padding:                    var(--lay_space_x1);
    border:                     var(--lay_border_main) solid var(--farge_alert_success_border);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background:                 var(--farge_alert_success_bg);
}

.flex_alert_red {

    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            space-between;
    align-items:                center;
    height:                     var(--lay_space_x3);
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    var(--lay_space_x1);
    border:                     var(--lay_border_main) solid var(--farge_red_2_border);
    border-radius:              var(--lay_border_radius);

    /* Color var(--farge_grey_1) */
    background:                 var(--farge_alert_fail_bg);
}
.flex_alert_red_abs {

    /* VSC farger */

    /* Display */
    position:                   absolute;
    top:                        var(--lay_editor_kode_fil_navn_alert_yp);
    left:                       var(--lay_editor_kode_fil_navn_alert_xp);;
    height:                     auto;
    width:                      auto;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    var(--lay_space_x025);
    border:                     var(--lay_border_main) solid var(--farge_red_11_editor); /* var(--farge_red_2_border) */
    border-top:                 none;
    /* border-radius:              var(--lay_border_radius);c*/

    /* Color */
    background:                 var(--farge_red_10_editor); /* var(--farge_alert_fail_bg) */

    /* Cursor */
    cursor:default;
    pointer-events:             visible;

}
.flex_alert_red_reg {
    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            space-between;
    align-items:                center;
    height:                     var(--lay_space_x3);
    width:                      var(--lay_space_x20);
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    var(--lay_space_x1);
    border:                     var(--lay_border_main) solid var(--farge_red_2_border);
    border-radius:              var(--lay_border_radius);

    /* Color var(--farge_grey_1) */
    background:                 var(--farge_alert_fail_bg);
}
.flex_alert_black_spill {
    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            space-between;
    align-items:                center;
    height:                     var(--lay_space_x3);
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding:                    var(--lay_space_x1);
    border:                     var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:              var(--lay_border_radius);

    /* Color var(--farge_grey_1) */
    background:                 var(--farge_grey_1);
}


/* ------------------------------------------ */
/* User profil > Disp */
/* ------------------------------------------ */

.flex_admin_section {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x05);

    /* Frame */
    padding:                    var(--lay_space_x05);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background-color:           var(--farge_grey_1);
}

.flex_mal_section {
    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x05);

    /* Frame */
    padding:                    var(--lay_space_x05);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background-color:           var(--farge_grey_1);
}

.flex_mal_section_NEW {
    /* Display */
    top: 0;
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x05);

    /* Frame */
    padding:                    var(--lay_space_x05);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background-color:           var(--farge_grey_1);
}


/* ------------------------------------------ */
/* User profil > Disp */
/* ------------------------------------------ */

.flex_profil_disp_kategori_nav {
    /* DEV ... */

    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            flex-start;
    align-items:                stretch;
    overflow-x:                 hidden;

    /* Frame */
    padding-left:               var(--lay_space_x4);
    border-bottom:              var(--lay_border_main) solid var(--farge_grey_4);

    /* Color */
    background-color:           transparent;
}
.flex_profil_disp_kategori_nav:hover {
    /* DEV ... */

    /* Display */
    overflow-x:                 scroll;
}


/* ------------------------------------------ */
/* User profil > Set (account) FJERN !IMPORTANT */
/* ------------------------------------------ */

.flex_profil_set_header {

    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            flex-start;
    align-items:                center;
    height:                     100%;
    width:                      100%;
    gap:                        var(--lay_space_x1);
    overflow:                   hidden;

    /* Color */
    background-color:           transparent;
}

.flex_profil_set_nav_wrapper {

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    height:                     auto; /* 100% */
    overflow:                   hidden;

    /* Frame
    padding-left: var(--lay_space_x05);
    padding-right: 0;
    padding-top: var(--lay_felt_padding_top);
    padding-bottom: var(--lay_felt_padding_btm);
    border-radius: var(--lay_border_radius); */

    /* Color */
    background-color:           transparent; /* var(--farge_grey_1); */
}

.flex_profil_set_nav_gruppe_wrapper {

    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            flex-start;
    align-items:                center;
    width:                      100%;
    height:                     auto; /* 100% */
    overflow:                   hidden;
    gap:                        var(--lay_space_x05);

    /* Frame */
    padding-left:               var(--lay_space_x05);
    padding-right:              0;
    padding-top:                var(--lay_felt_padding_top);
    padding-bottom:             var(--lay_felt_padding_btm);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background-color:           transparent; /* var(--farge_grey_1); */
}

.flex_profil_set_nav_item {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;
    height:                 var(--lay_nav_yy);
    overflow:               hidden;
    gap:                    var(--lay_space_x05);

    /* Frame */
    padding-left:           var(--lay_space_x05);
    padding-right:          0;
    padding-top:            var(--lay_felt_padding_top);
    padding-bottom:         var(--lay_felt_padding_btm);
    border-radius:          var(--lay_border_radius);
    border-left:            var(--lay_border_selected_marg_rr) solid transparent;

    /* Color */
    background-color:       transparent;
}
.flex_profil_set_nav_item:hover {

    /* Color */
    background-color:       var(--farge_grey_1);
}
.flex_profil_set_nav_item_selected {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;
    height:                 var(--lay_nav_yy);
    overflow:               hidden;
    gap:                    var(--lay_space_x05);

    /* Frame */
    padding-left:               var(--lay_space_x05);
    padding-right:              0;
    padding-top:                var(--lay_felt_padding_top);
    padding-bottom:             var(--lay_felt_padding_btm);
    border-top-right-radius:    var(--lay_border_radius);
    border-bottom-right-radius: var(--lay_border_radius);
    border-top-left-radius:     0;
    border-bottom-left-radius:  0;
    border-left:                var(--lay_border_selected_marg_rr) solid var(--farge_blue_1);

    /* Color */
    background-color:       var(--farge_grey_1);
}

.flex_profil_set_item {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-start;
    gap:                    var(--lay_space_x1);

    /* Color */
    background-color:       transparent;
}


/* ------------------------------------------ */
/* Felt */
/* ------------------------------------------ */

.flex_felt_100 {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 100%;
    overflow:               hidden;
    gap:                    var(--lay_space_x025); /* Sjekk denne */

    /* Frame */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       transparent; /* var(--farge_grey_1) */
}

.flex_felt_100_radiusless {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 100%;
    overflow:               hidden;
    gap:                    var(--lay_space_x025); /* Sjekk denne */

    /* Frame */
    border-radius:          0;

    /* Color */
    background-color:       transparent; /* var(--farge_grey_1) */
}

.flex_felt_L_row_right_item {

    /* Display
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    gap: var(--lay_space_x05); */

    /* Frame */
    margin-left:            auto;
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       transparent; /* var(--farge_grey_1); */
}

.flex_felt_center {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;
    height:                 100%;
    overflow:               hidden;

    /* Frame */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       transparent; /* var(--farge_grey_1); */
}

.flex_felt_version {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    /* align-items:            right; denne gir error */
    align-items:            center;
    width:                  auto;
    height:                 100%;
    overflow:               hidden;

    /* Frame */
    padding:                var(--lay_space_x025);
    margin-left:            auto;
    margin-right:           var(--lay_space_x1);
    border-radius:          var(--lay_border_radius);

    /* Color */
    color:                  var(--farge_beige_1);
    background-color:       blue; /* var(--farge_grey_1); */
}

.flex_felt_item {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 100%;
    overflow:               hidden;
    gap:                    var(--lay_space_x1);

    /* Frame
    padding-left: var(--lay_felt_padding_left);
    padding-right: var(--lay_felt_padding_right);
    padding-top: var(--lay_felt_padding_top);
    padding-bottom: var(--lay_felt_padding_btm); */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       transparent;
}


/* ------------------------------------------ */
/* Input */
/* ------------------------------------------ */

.felt_input {
    /* DEV: Fokus... */

    /* Display
    DEV: default: inline-block ???
    width: 18rem; */
    width:                  100%;
    transition:             box-shadow 0.3s ease; /* DEV */

    /* Frame
    margin-bottom: var(--lay_space_x1); */
    padding:                var(--lay_space_x05);
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    color:                  var(--farge_beige_1);
    background-color:       var(--farge_black_1);
}

.felt_input:focus {
    /* DEV: Fokus... */

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_fokus_border);
    box-shadow:             var(--farge_fokus_border) 0px 0px 0px 0px;
}

.felt_textarea {

    /* Display */
    /* height: auto; prøv */
    width:                  100%;

    /* Frame */
    padding:                var(--lay_space_x05);
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    background-color:       var(--farge_input_bg);
    color:                  var(--farge_beige_2);
}

.felt_textarea:hover {

    /* Frame
    border: var(--lay_border_main) solid var(--farge_grey_5); */

    /* Color */
    background-color:       var(--farge_input_bg_hover);
}

.felt_textarea:focus {

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_fokus_border) !important;

    /* Color */
    background-color:       var(--farge_input_bg_fokus);
}

.felt_textarea:disabled {

    /* Frame */
    padding:                var(--lay_space_x05);
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    background-color:       var(--farge_input_bg);
    color:                  var(--farge_beige_2);
}

.flex_felt_text_main_sub {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-between;
    align-items:            center;
    overflow:               hidden;
    width:                  100%;

    /* Color */
    background-color:       transparent;
}

.flex_felt_text_main_right {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-between;
    align-items:            center;
    overflow:               hidden;
    width:                  auto;

    /* Color */
    background-color:       transparent;
}

.flex_felt_text_tittel_right {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-between;
    align-items:            center;
    width:                  100%;
    height:                 100%;

    /* Color */
    background-color:       darkmagenta;
}

.flex_felt_text_tittel_right_wrapper {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-between;
    align-items:            center;
    width:                  100%;
    height:                 100%;

    /* Color */
    background-color:       transparent;
}

.flex_felt_text_info_sub {

    /* Note: Kun L fordi hjelpeinfo til H */

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_felt_text_info_right {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    overflow:               hidden;

    /* Tekst */
    white-space:            nowrap;

    /* Color */
    background-color:       transparent;
}


/* ------------------------------------------ */
/* Checkbox */
/* ------------------------------------------ */

.flex_checkbox_lbl_col_wrapper {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    gap:                    var(--lay_space_x05);
    width:                  100%;

    /* Color */
    background-color:       transparent;
}
.flex_checkbox_lbl_item_row_wrapper {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    gap:                    var(--lay_space_x05);

    /* Color */
    background-color:       transparent;
}

.flex_checkbox_tick {

    /* Display */
    position:               relative;
    left:                   var(--lay_checkbox_marg_left_extra);
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  auto;
    height:                 auto;
    overflow:               hidden;
    flex-shrink:            0;

    /* Frame */
    border-radius:          var(--lay_border_radius_checkbox);

    /* Color */
    background-color:       transparent;
}
.flex_checkbox_lbl {

    /* Display */
    position:               relative;
    left:                   var(--lay_checkbox_marg_left_extra);
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  auto;
    overflow:               hidden;
    flex-shrink:            0;

    /* Color */
    background-color:       transparent;
}

.ani_circle_click_wrapper {

    /* Display */
    display:                inline;
    min-width:              var(--lay_circle_click_wrapper_xx);
    max-width:              var(--lay_circle_click_wrapper_xx);

    /* Color */
    background-color:       transparent;
}
.ani_circle_click_pulse_effect {
    /* Display */

    background-color:       var(--farge_circle_click_pulse);
    height:                 0rem;
    width:                  0rem;
    border-radius:          100%;
    position:               relative;
    margin:                 auto;
}
.ani_circle_click {
    /* Display */

    position:                   absolute;
    left:                       var(--lay_circle_click_left_extra);
    top:                        var(--lay_circle_click_top_extra);
    background-color:           inherit;
    height:                     var(--lay_circle_click_xy);
    width:                      var(--lay_circle_click_xy);
    border-radius:              100%;
    opacity:                    var(--opacity_circle_click);
    animation:                  pulse var(--time_circle_click) ease-out infinite;
    animation-iteration-count:  1;
    animation-fill-mode:        forwards;
}
.ani_circle_click:nth-of-type(10) {
    /* Note: Ingen effekt (hvis flere sirkler) */
    /* FX */
    animation-delay:            -0.5s; /* Hardkode: Ubrukt */
}
@keyframes pulse {
    100% {
      transform:                scale(var(--scale_circle_click_transform));
      opacity:                  0;
    }
}


/* ------------------------------------------ */
/* Dropdown */
/* ------------------------------------------ */

.flex_dropdown_absolute_grey {

    /* DEV: header_desktop_dropdown_ch -> denne */

    /* Display */
    position:           absolute;
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              auto;
    height:             auto; /* prøv */
    gap:                var(--lay_space_x05);
    /* overflow: hidden; */

    /* Frame
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1c5);
    padding-left:       0; */
    padding:            var(--lay_dropdown_padding);
    border-radius:      var(--lay_border_radius_dropdown);
    border:             var(--lay_border_main) solid var(--farge_border_main);

    /* Color */
    background-color:   var(--farge_dropdown_bg);
    opacity:            var(--opacity_dropdown);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.flex_dropdown_flexbox_trans {
    /* DEV: header_desktop_dropdown_ch -> denne */

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              auto;
    height:             auto; /* prøv */
    gap:                var(--lay_space_x05);
    /* overflow: hidden; */

    /* Frame
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1c5);
    padding-left:       0; */
    padding:            var(--lay_dropdown_padding);
    border-radius:      var(--lay_border_radius_dropdown);
    border: 0 solid     var(--farge_border_main);

    /* Color */
    background-color:   transparent;
    opacity:            var(--opacity_dropdown);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.flex_dropdown_col {

    /* Display */
    display:            flex;
    flex-direction:     row;
    flex-wrap:          wrap; /* DEV: Sjekk om "flex-wrap" bør brukes på flere / hvilken effekt */
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             100%;
    gap:                var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
}


/* ------------------------------------------ */
/* Doc, blokk */
/* ------------------------------------------ */

.flex_blokk {

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             100%;
    overflow:           hidden;
    gap:                var(--lay_space_x05);

    /* Frame */
    margin-bottom:      var(--lay_space_x1);
    /*
    border: var(--lay_border_main) solid var(--farge_border_main_tmp);
    border-radius: var(--lay_border_radius); */

    /* DEV: Sjekk om permanent løsning (kanskje wrapper rundt blokk) */
    padding-left:       var(--lay_space_x1);
    padding-right:      var(--lay_space_x1);

    /* Color */
    background-color:   var(--farge_blokk_bg);
}

.flex_blokk_tittel_row {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             100%;
    overflow:           hidden;
    gap:                var(--lay_space_x05);

    /* Frame */
    padding-left:       0;
    padding-right:      var(--lay_felt_padding_right);
    padding-top:        var(--lay_felt_padding_top);
    padding-bottom:     var(--lay_felt_padding_btm);
    border-radius:      var(--lay_border_radius);

    /* Color */
    background-color:   transparent; /* var(--farge_grey_1); */
}

.flex_path_wrapper {

    /* Display */
    position:           fixed;
    top:                var(--lay_header_yy);
    left:               0;
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             auto;
    min-height:         var(--lay_nav_yy);
    gap:                var(--lay_space_x05);
    overflow:           hidden;
    z-index:            var(--z_index_path_wrapper); /* Denne */

    /* Color */
    background-color:   transparent;
}

.flex_path {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             100%;
    min-height:         var(--lay_nav_yy);

    /* overflow: hidden; */
    gap:                var(--lay_space_x05);
    flex-wrap:          wrap;
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    /* Frame */
    padding-left:       var(--lay_space_x1);
    border-top:         var(--lay_border_main) solid var(--farge_grey_6);
    border-bottom:      var(--lay_border_main) solid var(--farge_grey_6);
    /* Med CRUD:
    border-right: var(--lay_border_main) solid var(--farge_grey_6);
    */
    border-top-left-radius:     0;
    border-top-right-radius:    0; /* Med CRUD: var(--lay_border_radius) */
    border-bottom-left-radius:  0;
    border-bottom-right-radius: 0; /* Med CRUD: var(--lay_border_radius) */

    /* Color */
    background-color:   var(--farge_grey_2); /*  */
    opacity:                0.96; /* var(--opacity_header) */
}

.flex_lang_custom_resp {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-evenly;
    align-items:            center;
    width:                  100%;
    height:                 auto; /* PRØV default: Skal være på quest, header usikkert */
    gap:                    var(--lay_space_x1);
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}


/* ------------------------------------------ */
/* Spill */
/* ------------------------------------------ */

.flex_spill_uttrykk_hele {
    /* Display */
    display:                    flex;
    flex-direction:             row;
    justify-content:            center;
    align-items:                center;
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x05);

    /* Frame */
    padding:                    var(--lay_space_x05);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background-color:           var(--farge_grey_1);
}

.flex_spill_uttrykk_L_VS {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  50%;
    gap:                    var(--lay_space_x1);

    /* Color */
    background-color:       transparent;
}
.flex_spill_uttrykk_R_HS {

    /* Note > border-right er quickfix fordi høyre border i felt_spill_input_giga ikke vises */

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  50%;
    gap:                    0; /* var(--lay_space_x1) */

    /* Frame */
    border-right:           var(--lay_border_main) solid var(--farge_grey_4); /*  */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       transparent;
}

.flex_spill_felt_input {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;           /* 100% > Plassert til høyre */
    height:                 100%;
    overflow:               hidden;

    /* Frame */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       var(--farge_grey_1); /*  */

}

.flex_spill_oppg_hele {
    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%;
    overflow:                   hidden;
    gap:                        var(--lay_space_x05);

    /* Frame */
    padding:                    var(--lay_space_x05);
    border-radius:              var(--lay_border_radius);

    /* Color */
    background-color:           var(--farge_grey_1);
}
.flex_spill_oppg_main {
    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    gap:                    var(--lay_space_x1);

    /* Color */
    background-color:       transparent;
}

.flex_spill_oppg_main_stretch {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-between; /* Fungerer ikke fragement, men virker ellers */
    align-items:            center;
    width:                  100%;
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_spill_oppg_del_wrapper_row_B_stretch {

    /*
    - Frame-quickfix med
        - flex_spill_oppg_del_wrapper_row_B_stretch
        - flex_spill_icon_row
    - Sett på debug BG-farge os se hvordan den ikonene
    må skiftes slik at header-ikonene er helt synlige,
    og kommer over rad-ikonene
    */

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-end;
    width:                  100%;
    height:                 auto; /* PRØV default: Skal være på quest, header usikkert */
    overflow:               hidden;

    /* Frame */
    padding-right:          var(--lay_space_x025); /* Quickfix > Se flex_spill_icon_row med BG-farge */

    /* Color */
    background-color:       transparent;
}

.flex_spill_oppg_del_wrapper {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    gap:                    var(--lay_space_x05);

    /* Color */
    background-color:       transparent;
}

.flex_spill_icon_row {
    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            center;
    width:                  auto;
    height:                 auto; /* PRØV default: Skal være på quest, header usikkert */
    overflow:               hidden;
    gap:                    var(--lay_space_x05);

    /* Frame */
    margin-left:            auto;
    padding-right:          var(--lay_space_x05); /* Quickfix > Se flex_spill_icon_row med BG-farge */

    /* Color */
    background-color:       transparent;
}

.felt_spill_input_medium {

    /* DEV: Fokus... */

    /* Display */
    width:                  100%;
    height:                 2rem;
    /* transition:             box-shadow 0.3s ease; */

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_medium_size);
    font-weight:            var(--lay_font_medium_weight);
    text-decoration:        none;

    /* Color var(--farge_beige_1) */
    color:                  var(--farge_uttrykk_svar);
    background-color:       var(--farge_black_1);
}
.felt_spill_input_medium_xxx:focus {
    /* DEV: Fokus... */

    /* Frame
    border:                 var(--lay_border_main) solid var(--farge_fokus_border);
    */

    /* DEV > Kanskje unødvendig */
    box-shadow:             var(--farge_fokus_border) 0px 0px 0px 0px;

}

.felt_spill_input_giga {

    /* DEV: Fokus... */

    /* Display */
    width:                  auto;
    /* transition:             box-shadow 0.3s ease; */

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_uttrykk_giga_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color var(--farge_beige_1) */
    color:                  var(--farge_uttrykk_svar);
    background-color:       darkblue; /* var(--farge_black_1) */
}
.felt_spill_input_giga:focus {
    /* DEV: Fokus... */

    /* Frame
    border:                 var(--lay_border_main) solid var(--farge_fokus_border);
    */

    /* DEV > Kanskje unødvendig */
    box-shadow:             var(--farge_fokus_border) 0px 0px 0px 0px;

}

.flex_spill_tabell_hele {

    /* Forløpig samme som page core uten yp (header) */

    /* Display */
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                flex-start;
    width:                      100%; /* min-height: 100vh */
    overflow:                   hidden;
    gap:                        var(--lay_space_x1);

    /* Frame */
    padding-top:                0;
    padding-right:              0;
    padding-bottom:             0;
    padding-left:               0;
    margin-top:                 -1rem; /* page core > var(--lay_header_yy) */

    /* Color */
    background:                 transparent; /*  */
}

.flex_spill_tabell_rad_wrapper {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-between;
    align-items:            center;
    overflow:               hidden;
    width:                  100%;

    /* Color */
    background-color:       transparent;
}

.flex_spill_tabell_rad_wrapper_hist {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-start;
    overflow:               hidden;
    width:                  100%;

    /* Color */
    background-color:       transparent;
}

.flex_spill_tabell_hist_col_wrapper {

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    overflow:           hidden;
    gap:                var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
}

.flex_spill_tabell_cell_main_pros {
    /* Se > flex_author */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             100%;
    width:              20%; /* 16 */
    gap:                var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
}
.flex_spill_tabell_cell_hits_small_pros {
    /* Se > flex_author */

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    height:                 100%;
    width:                  10%;
    gap:                    var(--lay_space_x05);

    /* Color */
    background-color:       transparent;
}
.flex_spill_tabell_cell_hits_big_pros_header {
    /* Se > flex_author */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             100%;
    width:              60%;
    gap:                var(--lay_space_x05);
    overflow:           hidden;
    /* DEV > word-wrap:          break-word; */

    /* Frame */
    margin-right:       var(--lay_tabell_marg_R);

    /* Color */
    background-color:   transparent;
}
.flex_spill_tabell_cell_hits_big_pros_item_riktig {
    /* Se > flex_author */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             100%;
    width:              60%;
    gap:                var(--lay_space_x05);
    overflow:           hidden;
    /* DEV > word-wrap:          break-word; */

    /* Frame */
    margin-right:       var(--lay_tabell_marg_R);
    padding:            var(--lay_tabell_padding);
    border-radius:      var(--lay_border_radius);

    /* Color */
    background-color:   var(--farge_alert_success_bg);
}
.flex_spill_tabell_cell_hits_big_pros_item_feil {
    /* Se > flex_author */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             100%;
    width:              60%;
    gap:                var(--lay_space_x05);
    overflow:           hidden;
    /* DEV > word-wrap:          break-word; */

    /* Frame */
    margin-right:       var(--lay_tabell_marg_R);
    padding:            var(--lay_tabell_padding);
    border-radius:      var(--lay_border_radius);

    /* Color */
    background-color:   var(--farge_alert_fail_bg);
}

.spill_box_html {
    /* Display */
    position:               relative;
    top:                    0px;
    width:                  100%; /* Non-debug > 100%, debug > auto */
    min-height:             10rem;

    /* Frame */
    padding:                1rem; /* Non-debug > 1rem, debug > 0rem */

    /* Color */
    background-color:       var(--farge_grey_1); /*  */
}
.spill_box_html_xy_debug {
    /* Display */
    display:                flex;
    flex-direction:         row; /* Alternativ: row */
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  auto; /* Non-debug > 100%, debug > auto */
    height:                 auto;
    min-height:             18px;

    /* Frame */
    padding:                0px;
    margin:                 0px;

    /* Default html > De fleste browsere > Se https://granneman.com/webdev/coding/css/fonts-and-formatting/web-browser-font-defaults/ */
    /* Default > family = "Times New Roman", size = 16px */
    /* Default LaTex > family = "Computer Modern" */
    /*
    font-family:            "Times New Roman";
    - Cambria
    - Menlo

    */
    font-family:            "Times New Roman";
    font-size:              16px; /* 16px */
    font-style:             italic;

    /* Color */
    color:                  white;
    background-color:       darkgreen; /*  */
}
.spill_input_wrapper {

    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        space-between;
    align-items:            center;
    overflow:               hidden;
    width:                  100%;


    /* Color */
    background-color:       transparent;
    opacity:                1; /* Hidden > 0, Debug > Show > 1 */

}


/* ------------------------------------------ */
/* Editor kode */
/* ------------------------------------------ */

/* Hele */
.ek_flex_hele {

    /* Display */
    display:            flex;
    flex-direction:     column; /* Alternativ: row */
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             0px; /* auto, 0px overstyrt av get_yy_max, auto */
    overflow-y:         hidden;

    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_border_main);
    /* Debug: border: 1px solid red; */

    /* Color */
    background-color:   var(--farge_black_1); /* transparent */

    /* Cursor
    cursor: pointer;
    pointer-events: visible; */

}
.ek_inline_block_kode_term_col {

    /* Display  */
    display:            inline-block; /* inline-block, flex funker ikke */
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             auto;
    width:              100%;

    /* Frame (debug) */
    /* Debug: border: 2px solid orange; */

    /* Color  var(--farge_black_2) var(--farge_editor_kode_bg) */
    background-color:   red;
}
.ek_inline_block_kode_term_col_home_bg {

    /* Display */
    display:            inline-block; /* inline-block, flex, funker ikke */
    flex-direction:     column;
    justify-content:    flex-start; /* center */
    align-items:        flex-start; /* center */
    height:             100%; /* 100%, auto; */
    width:              100%;

    /* Frame (debug) */
    /* Debug: border: 2px solid orange; */

    /* Color */
    background-color:   var(--farge_editor_kode_bg);
}
.ek_inline_block_editor_kode_home_card {
    /* Aligner innholdet */

    /* Display */
    display:            flex; /* inline-block, flex funker ikke */
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             auto;
    width:              fit-content;
    gap:                var(--lay_space_x05);

    /* Frame */
    margin-top:         var(--lay_editor_kode_home_card_yp);
    margin-left:        var(--lay_editor_kode_home_card_xp);

    /* Frame (debug) */
    /* Debug: border: 2px solid orange; */

    /* Color darkblue */
    background-color:   transparent;
}

/* Rute */
.ek_flex_rute {

    /* Bruk debug under for å se rutene */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             auto; /* 100%, auto */

    /* Frame */
    border:             0px solid red; /* Debug */

    /* Color */
    background-color:   transparent;

}

/* Nav */
.ek_flex_nav {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    space-between;
    align-items:        center;
    width:              100%;
    height:             var(--lay_nav_yy) !important;
    min-height:         var(--lay_nav_yy) !important;
    max-height:         var(--lay_nav_yy) !important;
    gap:                var(--lay_space_x05);
    overflow:           hidden !important;

    /* Frame */
    padding-left:       var(--lay_space_x05);
    padding-right:      var(--lay_space_x05);
    border-bottom:      var(--lay_border_main) solid var(--farge_border_main);

    /* Color */
    background-color:   var(--farge_black_1);
}
.ek_flex_nav_item_multi_wrapper {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    space-between; /* flex-start */
    align-items:        center; /* center */
    width:              100%; /* auto */
    height:             var(--lay_nav_yy) !important;
    min-height:         var(--lay_nav_yy) !important;
    max-height:         var(--lay_nav_yy) !important;
    gap:                var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
}
.ek_inline_block_nav_item_wrapper {

    /* Display */
    display:            inline-block; /* ori: flex */
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              auto;
    height:             100%;

    /* Text */
    overflow-wrap:      break-word;
    white-space:        pre-line; /* leg: content: "\a"; white-space: pre; */

    /* Frame */
    padding:            var(--lay_space_x05);
    border-bottom:      var(--lay_border_selected_marg_rr) solid transparent;

    /* Color */
    background-color:   transparent;
}
.ek_inline_block_nav_item_wrapper_selected {

    /* Display */
    display:            inline-block; /* ori: flex */
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              auto;
    height:             100%;

    /* Text */
    overflow-wrap:      break-word;
    white-space:        pre-line; /* leg: content: "\a"; white-space: pre; */

    /* Frame */
    padding:            var(--lay_space_x05);
    border-bottom:      var(--lay_border_selected_marg_rr) solid var(--farge_editor_item_border_selected);

    /* Color */
    background-color:   transparent;
}
.ek_flex_nav_kode_run_wrapper {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              auto;
    height:             100%;
    gap:                var(--lay_space_x05);
    overflow:           hidden;

    /* Color */
    background-color:   transparent;
}

/* Main */
.ek_flex_main {

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             auto; /* auto (row), 100% */
    width:              100%;
    /* overflow-y: hidden; */

    /* Text: Kun for term_frontend */
    overflow-wrap:      break-word;
    white-space:        pre-line; /* leg: content: "\a"; white-space: pre; */

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    border:             0px solid darkblue; /* Debug */

    /* Color */
    background-color:   transparent;
}

/* Fil */
.ek_flex_main_fil_wrapper {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             var(--lay_editor_kode_fil_item_yy);
    overflow-y:         hidden;
    gap:                var(--lay_space_x05);

    /* Frame */
    padding-left:       var(--lay_space_x05);

    /* Color */
    background-color:   transparent;

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.ek_flex_main_fil_wrapper:hover {

    /* Color */
    background-color:   var(--farge_black_10_editor);
}
.ek_flex_main_fil_wrapper_colorless {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             var(--lay_editor_kode_fil_item_yy);
    overflow-y:         hidden;
    gap:                var(--lay_space_x05);

    /* Frame
    padding-left:       var(--lay_space_x05); */

    /* Color */
    background-color:   transparent;

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.ek_flex_main_fil_crud_wrapper {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-end;
    align-items:        center;
    width:              100%;
    height:             1.5rem;
    overflow-y:         hidden;
    gap:                var(--lay_space_x05);
    visibility:         hidden;

    /* Frame */
    padding-right:      var(--lay_space_x05);

    /* Color */
    background-color:   transparent;

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.ek_flex_main_fil_wrapper_selected {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             var(--lay_editor_kode_fil_item_yy);
    overflow-y:         hidden;
    gap:                var(--lay_space_x05);

    /* Frame */
    padding-left:       var(--lay_space_x05);

    /* Color */
    background-color:   var(--farge_black_12_editor);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.ek_flex_main_fil_wrapper:hover .ek_flex_main_fil_crud_wrapper {

    /* Display */
    visibility:         visible;

    /* Color */
    background-color:   var(--farge_black_10_editor);
}
.ek_flex_main_fil_wrapper_selected:hover .ek_flex_main_fil_crud_wrapper {

    /* Display */
    visibility:         visible;
}

.ek_flex_main_fil_wrapper_selected_edit_gyldig {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             var(--lay_editor_kode_fil_item_yy);
    overflow-y:         hidden;
    gap:                var(--lay_space_x05);

    /* Frame */
    padding-left:       var(--lay_space_x025); /* 0 */
    border:             var(--lay_border_main) solid var(--farge_fokus_border);

    /* Color */
    background-color:   var(--farge_black_12_editor);

    /* Cursor */
    cursor:text;
    pointer-events:     visible;
}
.ek_flex_main_fil_wrapper_selected_edit_ikke_gyldig {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             var(--lay_editor_kode_fil_item_yy);
    overflow-y:         hidden;
    gap:                var(--lay_space_x05);

    /* Frame */
    padding-left:       var(--lay_space_x025); /* 0 */
    border:             var(--lay_border_main) solid var(--farge_red_2_border);

    /* Color */
    background-color:   var(--farge_black_12_editor);

    /* Cursor */
    cursor:text;
    pointer-events:     visible;
}

/* Kode */
.ek_flex_rute_kode_bg_wrapper {
    /* Viktig for bl.a. at term_yy skal oppdateres */

    /* Debug */
    /* border: 2px solid green; */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             100%; /* 100%, auto */

    /* Color */
    background-color:   var(--farge_black_2);

}

/* Term */
.ek_flex_main_term {

    /* Display */
    display:            flex; /* Alt: inline (leg col), inline-blokk, flex */
    width:              100%;
    height:             auto;

    /* Text */
    overflow-wrap:      break-word;
    white-space:        pre-line; /* leg: content: "\a"; white-space: pre; */

    /* Frame */
    padding:            var(--lay_space_x05);

    /* Debug >          border: 1px solid red; */
    border-left:        var(--lay_border_main) solid var(--farge_border_main);

    /* Color */
    background-color:   transparent;

    /* DEV > Scrollbar-component overflow-y:             scroll !important; */

    /*
    overflow-y: auto;
    scroll-behavior: auto;
    */

}

.ek_flex_main_term_wrapper {
    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto; /* Alternativ: 100%  */
    /* overflow:            hidden; */

    /* Color transparent */
    background-color:       transparent;

    /* Frame */
    border:                 0px solid darkblue; /* Debug *0

    /* DEV > Scrollbar-component overflow-y: scroll !important; */

}
.ek_flex_main_term_frontend_iFrame {

    /* Display */
    display:            flex; /* ori: flex */
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             auto; /* 100%, auto */

    /* Text: DEV */
    overflow-wrap:      break-word;
    white-space:        pre-line;

    /* Frame */
    border:             0;
    margin:             0;
    padding:            0;
    box-sizing:         border-box;
    box-shadow:         none;
    outline:            0;

    /* Color */
    background-color:   var(--farge_editor_kode_term_frontend_canvas_bg);

    /* ORI (tut)
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    white-space: pre;
    right: 0;
    padding: 4px
    */
}

.ek_flex_main_term_output {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%; /* 100% */
    height:                 auto; /* PRØV default: Skal være på quest, header usikkert */
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.felt_textarea_term {

    /* Display */
    /* height: auto; prøv */
    width:                  auto; /* 100% */

    /* Frame
    border:                 0px solid red;
    padding:                var(--lay_space_x05);
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius); */
    padding-left:           var(--lay_space_x025);

    /* Frame */
    border: 0px solid transparent; /* Må være 0 for å override par-border */

    /* Txt
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none; */

    /* Txt */
    font-family:            var(--lay_font_term);
    font-size:              var(--lay_font_term_size);
    font-weight:            var(--lay_font_term_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent; /* var(--farge_input_bg) */
    color:                  var(--farge_beige_1); /* var(--farge_beige_2); */

    /* Cursor */
    cursor:                 text;

    /* Fjern resize-handle */
    resize:                 none;

}

.felt_textarea_term_BYPASS:hover {

    /* Frame
    border: var(--lay_border_main) solid var(--farge_grey_5); */

    /* Color */
    background-color:       var(--farge_input_bg_hover);
}

.felt_textarea_term_BYPASS:focus {

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_fokus_border) !important;

    /* Color */
    background-color:       var(--farge_input_bg_fokus);
}

.felt_textarea_term_BYPASS:disabled {

    /* Frame */
    padding:                var(--lay_space_x05);
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    background-color:       var(--farge_input_bg);
    color:                  var(--farge_beige_2);
}


/* ------------------------------------------ */
/* Quest */
/* ------------------------------------------ */

.flex_tittel_og_btn_ORI {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    space-between;
    align-items:        center;
    width:              100%;
    overflow:           hidden;

    /* Color */
    background-color:   transparent;
}

.flex_tittel_og_btn {

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    space-between;
    align-items:        center;
    width:              100%;
    overflow:           hidden;
    flex-wrap:          wrap;           /* PRØV */
    gap:                var(--lay_space_x1);

    /* Color */
    background-color:   transparent;
}

.flex_vote_list {

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        center;
    gap:                var(--lay_space_x05);
    min-width:          var(--lay_space_x5);
    overflow:           hidden;

    /* Frame */
    padding-left:       var(--lay_space_x1);
    padding-right:      var(--lay_space_x1);

    /* Color */
    background-color:   transparent;
}

.flex_vote_item {

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        center;
    min-width:          var(--lay_space_x4);
    overflow:           hidden;
    gap:                var(--lay_space_x1);

    /* Frame */
    padding-left:       var(--lay_space_x1);
    padding-right:      var(--lay_space_x1);

    /* Color */
    background-color:   transparent;
}

.flex_crud_author_tagg_quest {

    /* auto */

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto;
    /* gap: var(--lay_space_x1); */

    /* Frame
    padding:                var(--lay_space_x05); */

    /* Color */
    background-color:        transparent;
}

.flex_crud_author_quest {
    /* auto > alottment border */

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-end;
    align-items:        flex-start;
    width:              100%;
    height:             auto; /* prøv */
    gap:                var(--lay_space_x1);

    /* Color */
    background-color:   transparent;
}

.flex_tagg {
    /* 100% */

    /* Display */
    display:            flex;
    flex-direction:     row;
    flex-wrap:          wrap;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             100%;
    gap:                var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
}

.flex_author {
    /* 100% */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    min-width:          var(--lay_author_xx);
    max-width:          var(--lay_author_xx); /* Sjekk om klipper */
    height:             100%;
    gap:                var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
}

.flex_crud_text {
    /* 100% */

    /* Display : flex_row_LC_G05 */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    height:                 100%;
    width:                  100%;
    gap:                    var(--lay_space_x05);
    overflow:               hidden;

    /* Frame
    padding: var(--lay_space_x05); */

    /* Color var(--farge_black_1) */
    background-color:       transparent;
}

.flex_crud_exe {
    /* 100% */

    /* Display : flex_row_LC_G05 */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             100%;
    gap:                var(--lay_space_x05);
    overflow:           hidden;

    /* Frame
    padding: var(--lay_space_x05); */
    /* border-right: var(--lay_border_main) solid var(--farge_border_main); */

    /* Color var(--farge_black_1) */
    background-color:   transparent;
}

.flex_kom {
    /* auto */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             auto;

    /* Frame */
    padding:            var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
    color:              white;
}

.flex_kom_row {
    /* 100%; */

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             100%;
    overflow:           hidden;

    /* Color */
    background-color:   transparent;
}

.flex_kom_val {
    /* 100% */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    width:              var(--lay_space_x4);
    height:             100%;
    overflow-x:         hidden;

    /* Frame */
    padding:            var(--lay_space_x05);
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);

    /* Color */
    background-color:   transparent;
}

.flex_kom_item {
    /* 100% */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             100%;
    overflow-x:         hidden;

    /* Frame
    padding: var(--lay_space_x05); */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight);
    text-decoration:    none;
    word-wrap:          break-word;
    word-break:         break-all;

    /* Color */
    color:              var(--farge_beige_2);
    background-color:   transparent;
}

.flex_kom_item_create {
    /* 100% */

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;
    height:             100%;
    overflow-x:         hidden;

    /* Frame
    padding: var(--lay_space_x05); */
    padding-bottom:     var(--lay_space_x05);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight);
    text-decoration:    none;
    word-wrap:          break-word;
    word-break:         break-all;

    /* Color */
    color:              var(--farge_beige_2);
    background-color:   transparent; /* var(--farge_grey_1) */
}


/* ------------------------------------------ */
/* Svar arr, svar nytt */
/* ------------------------------------------ */

.inline_block_svar_arr {
    /* auto */

    /* Display */
    display:            inline-block;
    width:              100%;
    height:             auto;

    /* Color var(--farge_black_1) */
    background-color:   transparent;
}

.flex_svar_arr {
    /* 100 % > alot border */

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             100%; /* prøv -> ingen */
    width:              100%;
    overflow:           hidden;

    /* Frame */
    /* border-right: var(--lay_border_main) solid var(--farge_border_main);
    border-bottom: var(--lay_border_main) solid var(--farge_border_main);
    border-top: var(--lay_border_main) solid var(--farge_border_main); */

    /* Color */
    background-color:   transparent;
}

.flex_svar_arr_content {
    /* 100 % > alot border */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    overflow:           hidden;

    /* Frame
    border-left: var(--lay_border_main) solid var(--farge_border_main); */

    /* Color */
    background-color:   transparent;
}

.flex_tagg_author_crud_svar_arr {
    /* 100 % */

    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             100%; /* auto */
    /* gap: var(--lay_space_x1); */

    /* Frame */
    padding:            var(--lay_space_x05);

    /* Color var(--farge_black_1) */
    background-color:   transparent;
}

.flex_author_no_tagg {
    /* 100 % > alottment border */

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-end;
    align-items:        flex-start;
    height:             100%;
    width:              100%;
    gap:                var(--lay_space_x1);

    /* Frame */
    padding:            var(--lay_space_x05);
    /* border-right: var(--lay_border_main) solid var(--farge_border_main); */
    /* border-bottom: 0; */

    /* Color */
    background-color:   transparent;
}

.flex_svar_create {
    /* auto */

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto;
    min-height:             20rem;
    gap:                    var(--lay_space_x1);

    /* Color var(--farge_black_1) */
    background-color:       transparent;
}


/* ------------------------------------------ */
/* Modal */
/* ------------------------------------------ */

.modal_loading_main {
    position:               absolute;
    top:                    50vh; /* 40px; */
    left:                   50vw; /* 40px; */
    right:                  0px;
    bottom:                 0px;
    background-color:       transparent;
    transform:              translate(-25px, -50px); /* CubeSpinner */
    /* MagicSpinner: transform: translate(-50px, -100px); */
}

.modal_loading_overlay {
    position:               fixed;
    top:                    0;
    left:                   0;
    right:                  0;
    bottom:                 0;
    background-color:       transparent;
    opacity:                1; /* Alternativ > 0.5 */
}

.modal_black_screen_overlay {
    position:               fixed;
    top:                    var(--lay_header_yy);
    left:                   0;
    right:                  0;
    bottom:                 0;
    background-color:       var(--farge_black_2);
    opacity:                1;
    z-index:                var(--z_index_black_screen_overlay);
}


/* ------------------------------------------ */
/* Generelle */
/* ------------------------------------------ */

.flex_row_LT {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto; /* PRØV default: Skal være på quest, header usikkert */
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_row_LT_G1 {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    gap:                    var(--lay_space_x1);

    /* Color */
    background-color:       transparent;
}

.flex_row_LT_G05 {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    gap:                    var(--lay_space_x05);

    /* Color */
    background-color:       transparent;
}

.flex_row_LT_auto_G1 {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        stretch;
    align-items:            flex-start;
    /* width: auto; */
    gap:                    var(--lay_space_x1);

    /* Color */
    background-color:       transparent;
}

.flex_row_RC_G05 {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-end;
    align-items:            center;
    width:                  auto;
    height:                 auto; /* PRØV default: Skal være på quest, header usikkert */
    overflow:               hidden;
    gap:                    var(--lay_space_x05);

    /* Color */
    background-color:       transparent;
}

.flex_row_LC {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_row_LC_G1 {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    gap:                    var(--lay_space_x1);
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_row_LC_G05 {
    /* Display */
    display:                flex;
    flex-direction:         row;
    justify-content:        flex-start;
    align-items:            center;
    gap:                    var(--lay_space_x05);
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_row_LC_G05_wrap {
    /* Display */
    display:                flex;
    flex-direction:         row;
    flex-wrap:              wrap;
    justify-content:        flex-start;
    align-items:            center;
    gap:                    var(--lay_space_x05);
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_col_TL {
    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto; /* PRØV: Skal være på quest, ikke de andre */
    overflow:               hidden;

    /* Color */
    background-color:       transparent;
}

.flex_col_TL_G1 {
    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    overflow:               hidden;
    gap:                    var(--lay_space_x1);

    /* Color */
    background-color:       transparent;
}

.flex_col_TC {
    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            center;
    /* Alternativ: 100 % */

    /* Color */
    background-color:       transparent;
}

.flex_col_TC_100 {
    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;
    /* Alternativ: 100 % */

    /* Color */
    background-color:       transparent;
}


/* ------------------------------------------ */
/* Reponsive > Søkeord > CSS_Media */
/* ------------------------------------------ */

/* Mobile 2 > 0-320 */
@media (min-width: 0px) {


    /* ------------------------------------------ */
    /* Page */
    /* ------------------------------------------ */

    .flex_page_core_bg_fade_in {

        /* Color */
        background-size:            400%;

    }


    /* ------------------------------------------ */
    /* Landing */
    /* ------------------------------------------ */

    .flex_landing_card_lang {

        max-width: var(--lay_card_mobile_x);

    }


    /* ------------------------------------------ */
    /* Doc, blokk */
    /* ------------------------------------------ */

    .flex_lang_custom_resp {

        /* Display */
        flex-direction:             column;

    }

}

/* Mobile 1, Desktop */
@media (min-width: 530px) {


    /* ------------------------------------------ */
    /* Page */
    /* ------------------------------------------ */

    .flex_page_core_bg_fade_in {

        /* Color */
        background-size:            300%;

    }


    /* ------------------------------------------ */
    /* Landing */
    /* ------------------------------------------ */

    .flex_landing_card_lang {

        max-width: var(--lay_card_mobile_1);

    }


    /* ------------------------------------------ */
    /* Doc, blokk */
    /* ------------------------------------------ */

    .flex_lang_custom_resp {

        /* Display */
        flex-direction:         row;

    }

}

/* Desktop */
@media (min-width: 701px) {


    /* ------------------------------------------ */
    /* Page */
    /* ------------------------------------------ */

    .flex_page_core_bg_fade_in {

        /* Color */
        background-size:            150%;

    }


    /* ------------------------------------------ */
    /* Landing */
    /* ------------------------------------------ */

    .flex_landing_card_lang {

        max-width: var(--lay_card_desktop);

    }

}

/* Desktop 1000 */
@media (min-width: 1000px) {


    /* ------------------------------------------ */
    /* Page */
    /* ------------------------------------------ */

    .flex_page_core_bg_fade_in {

        /* Color */
        background-size:            100%;

    }


    /* ------------------------------------------ */
    /* Landing */
    /* ------------------------------------------ */

    .flex_landing_card_lang {

        max-width: var(--lay_card_desktop);

    }

}

/* Desktop 1200 */
@media (min-width: 1200px) {


    /* ------------------------------------------ */
    /* Page */
    /* ------------------------------------------ */

    .flex_page_core_bg_fade_in {

        /* Color */
        background-size:            100%;

    }


    /* ------------------------------------------ */
    /* Landing */
    /* ------------------------------------------ */

    .flex_landing_card_lang {

        max-width: var(--lay_card_desktop);

    }
}

/* Desktop 1400 */
@media (min-width: 1400px) {


    /* ------------------------------------------ */
    /* Page */
    /* ------------------------------------------ */

    .flex_page_core_bg_fade_in {

        /* Color */
        background-size:            100%;

    }


    /* ------------------------------------------ */
    /* Landing */
    /* ------------------------------------------ */

    .flex_landing_card_lang {

        max-width: var(--lay_card_desktop);

    }

}


/* ------------------------------------------ */
/* Notes */
/* ------------------------------------------ */

/* Alle flex-bokser -> overflow: hidden */


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
