/* ------------------------------------------ */
/* Start, html, body */
/* ------------------------------------------ */

* {
    margin:                 0;
    padding:                0;
    box-sizing:             border-box;
    box-shadow:             none;
    outline:                0;
}

html {
    scroll-behavior:        smooth;
}

html, body {
    overflow-x:             hidden;
    overflow-y:             auto;
    font-family:            "Times New Roman", Times, serif; /* Default: Overridden */
}


/* ------------------------------------------ */
/* a */
/* ------------------------------------------ */

a {
    text-decoration:        none;
}


/* ------------------------------------------ */
/* Input, textarea */
/* ------------------------------------------ */

input[type="file"] {
    /* Styling: Gjem default html input-element */
    /* Display */
    display:                none;
}

textarea {
    /* Display */
    width:                  100%;
    height:                 0; /* ingen effekt */
    /* DEV: Browser-avhengig?
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    */
    /* Color */
    background-color:       red;
}


/* ------------------------------------------ */
/* Select, option */
/* ------------------------------------------ */

select {
    /* Display */
    display:                none;
}

option {
    /* Display */
    display:                none;
}

option:not(:checked) {
    /* Display */
    display:                none;
}


/* ------------------------------------------ */
/* Scrollbox */
/* - Foreløpig skjult -> display: none; */
/* ------------------------------------------ */

::-webkit-scrollbar {
    /* Scrollbar bredde */
    /* Display */
    display:                none;
    width:                  var(--lay_space_x05);
    height:                 var(--lay_space_x05);
}

::-webkit-scrollbar-track {
    /* Scrollbar track */

    /* Color */
    background:             var(--farge_grey_4);
}

::-webkit-scrollbar-thumb {
    /* Scrollbar handle */
    /* Frame */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background:             var(--farge_grey_6);
}

/* css-error
::-webkit-scrollbar-thumb:hover {
    // Scrollbar handle on hover
    // Color
    background:             var(--farge_grey_6);
}
*/


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
