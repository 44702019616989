/* ------------------------------------------ */
/* Ingen effekt */
/* ------------------------------------------ */

.cropper-container {
  direction:            ltr;
  font-size:            0;
  line-height:          0;
  position:             relative;
  -ms-touch-action:     none;
  touch-action:         none;
  -webkit-user-select:  none;
  -moz-user-select:     none;
  -ms-user-select:      none;
  user-select:          none;
}

.cropper-container img {
  display:              block;
  height:               100%;
  /* css-error          image-orientation:    0deg;*/
  max-height:           none !important;
  max-width:            none !important;
  min-height:           0 !important;
  min-width:            0 !important;
  width:                100%;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom:               0;
  left:                 0;
  position:             absolute;
  right:                0;
  top:                  0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow:             hidden;
}

.cropper-drag-box {
  background-color:     transparent; /* ori: #fff */
  opacity:              0;
}


/* ------------------------------------------ */
/* Deler */
/* ------------------------------------------ */

/* Cropper modal bg */
.cropper-modal {
  background-color:     var(--farge_cropper_modal_bg); /* ori: #000 */
  opacity:              0.5;
}

/* Bilde box sirkel */
.cropper-view-box {
    box-shadow:         0 0 0 1px var(--farge_cropper_bilde_box_sirkel);
    outline:            0;
}
.cropper-crop-box, .cropper-view-box {
    border-radius:      50%;
}

/* Bilde box ramme */
.cropper-view-box {
  display:              block;
  height:               100%;
  outline:              var(--lay_cropper_bilde_box_ramme_border_yy) solid var(--farge_cropper_bilde_box_ramme); /* ori: #39f */
  outline-color:        var(--farge_cropper_bilde_box_ramme); /* ori: rgba(51, 153, 255, 0.75) */
  overflow:             hidden;
  width:                100%;
}

/* Ingen effekt */
.cropper-dashed {
  border:               0 dashed #eee; /* ori: border: 0 dashed #eee; */
  display:              block;
  opacity:              0.5;
  position:             absolute;
}
.cropper-dashed.dashed-h {
  border-bottom-width:  1px;
  border-top-width:     1px;
  height:               calc(100% / 3);
  left:                 0;
  top:                  calc(100% / 3);
  width:                100%;
}
.cropper-dashed.dashed-v {
  border-left-width:    1px;
  border-right-width:   1px;
  height:               100%;
  left:                 calc(100% / 3);
  top:                  0;
  width:                calc(100% / 3);
}
.cropper-center {
  display:              block;
  height:               0;
  left:                 50%;
  opacity:              0.75;
  position:             absolute;
  top:                  50%;
  width:                0;
}

/* Crosshairs i midten */
.cropper-center::before,
.cropper-center::after {
  background-color:     var(--farge_cropper_bilde_box_siktet_i_midten); /* ori: #eee */
  content:              ' ';
  display:              block;
  position:             absolute;
}
.cropper-center::before {
  height:               1px;
  left:                 -3px;
  top:                  0;
  width:                7px;
}
.cropper-center::after {
  height:               7px;
  left:                 0;
  top:                  -3px;
  width:                1px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  display:              block;
  height:               100%;
  opacity:              0.1;
  position:             absolute;
  width:                100%;
}

/* Bilde box filter */
.cropper-face {
  background-color:     var(--farge_cropper_bilde_box_filter); /* ori: #fff */
  left:                 0;
  top:                  0;
}

/* Bilde box ramme bg wrapper */
.cropper-line {
  background-color:     var(--farge_cropper_bilde_box_ramme_bg_wrapper); /* ori: #39f */
}
.cropper-line.line-e {
  cursor:               ew-resize;
  right:                -3px;
  top:                  0;
  width:                5px;
}
.cropper-line.line-n {
  cursor:               ns-resize;
  height:               5px;
  left:                 0;
  top:                  -3px;
}
.cropper-line.line-w {
  cursor:               ew-resize;
  left:                 -3px;
  top:                  0;
  width:                5px;
}
.cropper-line.line-s {
  bottom:               -3px;
  cursor:               ns-resize;
  height:               5px;
  left:                 0;
}

/* Bilde box grabbers */
.cropper-point {
    /* Display */
    height:             var(--lay_cropper_bilde_box_grabbers_small_xx);
    width:              var(--lay_cropper_bilde_box_grabbers_small_yy);
    opacity:            0.75;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color (overstyres av de individuelle */
    background-color:   var(--farge_cropper_bilde_box_grabbers); /* ori: #39f */
}
.cropper-point.point-e {
    /* Display */
    cursor:             ew-resize;
    margin-top:         -3px;
    right:              -3px;
    top:                50%;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_e);
}
.cropper-point.point-n {
    /* Display */
    cursor:             ns-resize;
    left:               50%;
    margin-left:        -3px;
    top:                -3px;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_n);
}
.cropper-point.point-w {
    /* Display */
    cursor:             ew-resize;
    left:               -3px;
    margin-top:         -3px;
    top:                50%;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_w);
}
.cropper-point.point-s {
    /* Display */
    bottom:             -3px;
    cursor:             s-resize;
    left:               50%;
    margin-left:        -3px;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_n);
}
.cropper-point.point-ne {
    /* Display */
    cursor:             nesw-resize;
    right:               -3px;
    top:                -3px;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_ne);
}
.cropper-point.point-nw {
    /* Display */
    cursor:             nwse-resize;
    left:               -3px;
    top:                -3px;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_nw);
}
.cropper-point.point-sw {
    /* Display */
    bottom:             -3px;
    cursor:             nesw-resize;
    left:               -3px;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_all_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_sw);
}
.cropper-point.point-se {
    /* Display */
    bottom:             var(--lay_cropper_bilde_box_grabbers_big_rp); /* ori: -3px */
    right:              var(--lay_cropper_bilde_box_grabbers_big_rp); /* ori: -3px */
    cursor:             nwse-resize;
    height:             var(--lay_cropper_bilde_box_grabbers_big_xx);
    width:              var(--lay_cropper_bilde_box_grabbers_big_xx);
    opacity:            1;

    /* Frame */
    border-radius:      var(--lay_cropper_bilde_box_grabbers_se_border_radius);

    /* Color */
    background-color:   var(--farge_cropper_bilde_box_grabbers_se);
}

/* Responsive: ori
@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}
@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}
@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}
*/


/* ------------------------------------------ */
/* Ingen effekt */
/* ------------------------------------------ */

.cropper-point.point-se::before {
  background-color:     #39f; /* ori: #39f */
  bottom:               -50%;
  content:              ' ';
  display:              block;
  height:               200%;
  opacity:              0;
  position:             absolute;
  right:                -50%;
  width:                200%;
}

.cropper-invisible {
  opacity:              0;
}

.cropper-bg {
  background-image:     url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
  display:              block;
  height:               0;
  position:             absolute;
  width:                0;
}

.cropper-hidden {
  display:              none !important;
}

.cropper-move {
  cursor:               move;
}

.cropper-crop {
  cursor:               crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor:               not-allowed;
}

/* Egen custom wrapper */
.cropper_wrapper {
    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    height:             100%;
    overflow:           hidden;

    /* Frame */
    padding:            0; /* var(--lay_space_x1) */
    border:             var(--lay_border_main) solid var(--farge_grey_2);
    border-radius:      var(--lay_border_radius);

    /* Color */
    background:         var(--farge_black_2);
}

.cropper_header {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    space-between;
    align-items:        flex-start;
    width:              100%;
    height:             var(--lay_cropper_header_yy);
    overflow:           hidden;

    /* Frame */
    padding:            var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_2);
    border-top-left-radius:     var(--lay_border_radius);
    border-top-right-radius:    var(--lay_border_radius);

    /* Color */
    background:         var(--farge_grey_1);
}

.cropper_btn_wrapper {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        flex-start;
    width:              100%;
    gap:                var(--lay_space_x1);

    /* Frame */
    padding:            var(--lay_space_x1);

    /* Color */
    background-color:   transparent;
}

/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
