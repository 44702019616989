/* ------------------------------------------ */
/* Root */
/* ------------------------------------------ */

:root {

    /* ------------------------------------------ */
    /* Layout */
    /* ------------------------------------------ */

    /* ------------------------------------------ */
    /* Font */
    /* ------------------------------------------ */

    /* Font family */
    --lay_font:                     "Roboto", sans-serif;
    --lay_font:                     "Roboto", sans-serif;
    --lay_font_term:                "Menlo"; /* editor kode */

    /* Param: Font sizes, weight, bold */
    /* Kode editor (Ace) default: 14px -> 0.875rem */
    --lay_font_tagg_size:           0.6rem;
    --lay_font_tagg_weight:         600;
    --lay_font_term_size:           12px; /* =0.75rem (ace) */
    --lay_font_term_weight:         500;
    --lay_font_mikro_size:          0.6rem;
    --lay_font_mikro_weight:        600;
    --lay_font_mini_size:           0.7rem;
    --lay_font_mini_weight:         500;
    --lay_font_liten_size:          0.8rem;
    --lay_font_liten_weight:        400;
    --lay_font_liten_weight_bold:   900;
    --lay_font_norm_size:           0.95rem;    /* GH: 0.9, MZ: 1.0 */
    --lay_font_norm_weight:         100;        /* GH: 400 */
    --lay_font_norm_weight_bold:    500;
    --lay_font_modal_size:          1rem;
    --lay_font_modal_weight_bold:   600;
    --lay_font_medium_size:         1.1rem;
    --lay_font_medium_size_landing: 1.4rem;
    --lay_font_medium_weight:       var(--lay_font_norm_weight);
    --lay_font_medium_weight_landing:   400;
    --lay_font_medium_weight_bold:  500;
    --lay_font_stor_size:           1.5rem;
    --lay_font_stor_weight:         var(--lay_font_norm_weight); /* ... mega (2.0rem; 900); giga (2.5rem, 900) */

    --lay_font_mega_1_size_card:    2rem; /* 2rem, 900 */
    --lay_font_mega_1_weight_card:  500;
    --lay_font_mega_1_size:         3rem; /* 2rem, 900 */
    --lay_font_mega_1_weight:       600;
    --lay_font_mega_2_size:         1.5rem; /* 2rem, 900 */
    --lay_font_mega_2_weight:       600;

    --lay_font_card_landing_size:   1.75rem; /* 2rem, 900 */
    --lay_font_card_weight:         500;

    --lay_font_uttrykk_giga_size:   4rem; /* 2rem, 900 */

    /* ------------------------------------------ */
    /* Space */
    /* ------------------------------------------ */

    /* Space rem */
    --lay_space_xn05:               -0.5rem;
    --lay_space_xn025:              -0.25rem;
    --lay_space_xn1:                -1rem;
    --lay_space_x0:                 0rem;
    --lay_space_x025:               0.25rem;
    --lay_space_x03:                0.3rem;     /* dropdown-pil */
    --lay_space_x05:                0.5rem;
    --lay_space_x075:               0.75rem;
    --lay_space_x08:                0.8rem;     /* dropdown-pil margin-top ??? */
    --lay_space_x1:                 1rem;
    --lay_space_x1c25:              1.25rem;
    --lay_space_x1c5:               1.5rem;
    --lay_space_x1c75:              1.75rem;
    --lay_space_x2:                 2rem;
    --lay_space_x2c25:              2.25rem;    /* DEV: Se "Btn height"-notat */
    --lay_space_x3:                 3rem;
    --lay_space_x4:                 4rem;
    --lay_space_x4c5:               4.5rem;             /* Icon > JS */
    --lay_space_x4c5_koeff:         calc(4.5*1.14rem);  /* Icon > JS */
    --lay_space_x5:                 5rem;       /* vote column width */
    --lay_space_x6:                 6rem;
    --lay_space_x8:                 8rem;
    --lay_space_x9:                 9rem;               /* Icon > JS */
    --lay_space_x9_koeff:           calc(9*1.14rem);    /* Icon > JS */
    --lay_space_x10:                10rem;
    --lay_space_x12:                12rem;
    --lay_space_x16:                16rem;      /* Footer */
    --lay_space_x20:                20rem;      /* reg box */
    --lay_space_x30:                30rem;      /* Card > Lang > Landing */
    --lay_space_x50:                50rem;      /* gridd profil set (tmp) */

    /* Space px */
    --lay_space_n10px:              -10px;
    --lay_space_n5px:               -5px;
    --lay_space_n3px:               -3px;
    --lay_space_075px:              0.75px;
    --lay_space_1px:                1px;
    --lay_space_1c5px:              1.5px;
    --lay_space_2px:                2px;
    --lay_space_3px:                3px;
    --lay_space_4px:                4px;
    --lay_space_5px:                5px;
    --lay_space_6px:                6px;
    --lay_space_10px:               10px;
    --lay_space_15px:               15px;
    --lay_space_16px:               16px;
    --lay_space_20px:               20px;
    --lay_space_24px:               24px;
    --lay_space_28px:               28px;
    --lay_space_36px:               36px;       /* =2.25rem DEV: Se "Btn height"-notat */
    --lay_space_54px:               54px;       /* 36 * 1.5 */
    --lay_space_68px:               68px;
    --lay_space_140px:              140px;

    /* Space % */
    --lay_space_10p:                10%;

    /* PRØV > Responsive */
    --lay_mobile_2:                 530px;


    /* ------------------------------------------ */
    /* Components */
    /* ------------------------------------------ */

    /* Editor rich (Quill) */
    --lay_editor_rich_yy_min:       var(--lay_space_x5);
    --lay_editor_rich_padding_yy:   var(--lay_space_5px);
    --lay_editor_rich_padding_xx:   var(--lay_space_10px);;
    --lay_editor_rich_marg_yy:      var(--lay_space_5px);
    --lay_editor_rich_border_radius:    var(--lay_space_3px);
    --lay_editor_rich_font_padding_yy:  var(--lay_space_2px);
    --lay_editor_rich_font_padding_xx:  var(--lay_space_4px);
    --lay_editor_rich_icon_yy:          var(--lay_space_24px);  /* 24 px */
    --lay_editor_rich_icon_padding_yy:  var(--lay_space_2px);   /* 2px */
    --lay_editor_rich_icon_padding_xx:  var(--lay_space_2px);   /* 4px */
    --lay_editor_rich_icon_color_picker_bg_xx:      var(--lay_space_140px); /* DEV: Sjekk ut hva for noe */
    --lay_editor_rich_icon_color_picker_item_rr:    var(--lay_space_16px);  /* 16px */
    --lay_editor_rich_icon_color_picker_item_marg:  var(--lay_space_2px);
    --lay_editor_rich_icon_color_picker_item_border_radius: var(--lay_space_2px);
    --lay_editor_rich_block_quote_rect: var(--lay_space_4px);
    --lay_editor_rich_block_quote_marg: var(--lay_space_5px);
    --lay_editor_rich_block_quote_padding_left:     var(--lay_space_16px);
    --lay_editor_rich_line_height_yy:   1.67; /* DEV: Ikke hardkodet og match med andre 1.42 */

    /* Kode editor */
    --lay_editor_kode_term_yy_min:              var(--lay_space_x4);
    --lay_editor_kode_fil_item_yy:              var(--lay_space_x1c5);
    --lay_editor_kode_fil_navn_alert_yp:        var(--lay_space_68px);
    --lay_editor_kode_fil_navn_alert_xp:        var(--lay_space_28px);
    --lay_editor_kode_home_card_xp:             var(--lay_space_x2);
    --lay_editor_kode_home_card_yp:             var(--lay_space_x2);

    /* Cropper */
    --lay_cropper_header_yy:                            var(--lay_space_54px);
    --lay_cropper_bilde_box_ramme_border_yy:            var(--lay_space_1px);
    --lay_cropper_bilde_box_grabbers_all_border_radius: 50%;
    --lay_cropper_bilde_box_grabbers_small_xx:          var(--lay_space_5px);
    --lay_cropper_bilde_box_grabbers_small_yy:          var(--lay_space_5px);
    --lay_cropper_bilde_box_grabbers_se_border_radius:  50%;
    --lay_cropper_bilde_box_grabbers_big_xx:            var(--lay_space_20px);
    --lay_cropper_bilde_box_grabbers_big_yy:            var(--lay_space_20px);
    --lay_cropper_bilde_box_grabbers_big_rp:            var(--lay_space_n10px);

    /* Strek */
    --lay_strek_yy:             var(--lay_space_1px);

    /* Rotering */
    --lay_rotering_top:         var(--lay_space_x05); /* DEV: Splitter collapse */
    --lay_rotering_top_anti:    var(--lay_space_x0); /* DEV: Splitter collapse */

    /* SVG */
    --lay_svg_stroke:           var(--lay_space_1c5px);
    --lay_svg_stroke_thin:      var(--lay_space_075px);

    /* Img */
    --lay_img_rr_mini:          var(--lay_space_x1c5);
    --lay_img_rr_mikro:         var(--lay_space_x2);
    --lay_img_rr_liten:         var(--lay_space_x3); /* author */
    --lay_img_rr_norm:          var(--lay_space_x4);
    --lay_img_rr_medium:        var(--lay_space_x6);
    --lay_img_rr_stor:          var(--lay_space_x12);

    /* Icon */
    --lay_icon_wrapper_xy:      var(--lay_space_15px); /* Søkeord > icon_xp, 20 */
    --lay_icon_wrapper_marg_xp: var(--lay_space_n5px); /* 20 */
    --lay_icon_forside_1_xx:    var(--lay_space_x10);
    --lay_icon_forside_1_yy:    var(--lay_icon_forside_1_xx);
    --lay_icon_forside_1_xx_js: var(--lay_space_x9);
    --lay_icon_forside_1_yy_js: var(--lay_space_x9_koeff);
    --lay_icon_forside_2_xx_js: var(--lay_space_x4c5);
    --lay_icon_forside_2_yy_js: var(--lay_space_x4c5_koeff);
    --lay_icon_forside_2_xx:    var(--lay_space_x5);
    --lay_icon_forside_2_yy:    var(--lay_icon_forside_2_xx);
    --lay_icon_forside_top_yp:  var(--lay_space_x3);

    /* Header */
    --lay_header_yy:                var(--lay_space_x4); /* header, ubrukt: --lay_footer_yy */
    --lay_header_super_marg_L_xx:   var(--lay_space_x05); /* 18px */
    --lay_header_forward_liten_header_icon_top:     var(--lay_space_10px);
    --lay_header_expanded_liten_header_icon_top:    var(--lay_space_6px);
    --lay_header_expanded_liten_header_icon_left:   var(--lay_space_n3px);
    --lay_header_dropdown_top:      var(--lay_space_x3);

    /* Footer */
    --lay_footer_mobile_yy:         var(--lay_space_x16); /* header, ubrukt: --lay_footer_yy */

    /* Nav */
    --lay_nav_yy:                   var(--lay_space_36px); /* DEV: Se "Btn height"-notat */

    /* Dropdown */
    --lay_dropdown_gap_yy:          var(--lay_space_x1);
    --lay_dropdown_padding:         var(--lay_space_x05);

    /* Checkbox */
    --lay_checkbox_marg_left_extra: var(--lay_space_xn1);

    /* Animations */
    --lay_circle_click_left_extra:  var(--lay_space_xn025);
    --lay_circle_click_top_extra:   var(--lay_space_xn05);
    --lay_circle_click_wrapper_xx:  var(--lay_space_x05);
    --lay_circle_click_xy:          var(--lay_space_x1);

    /* Card */
    --lay_card_desktop:             var(--lay_space_x30);
    --lay_card_mobile_1:            var(--lay_space_x30);
    --lay_card_mobile_2:            var(--lay_space_x20);
    --lay_card_mobile_x:            var(--lay_space_x16);

    /* CRUD */
    --lay_crud_yy:                  var(--lay_space_36px); /* --lay_space_24px */

    /* Author */
    --lay_author_xx:                var(--lay_space_x8);

    /* Tagg */
    --lay_btn_tagg_yy:              var(--lay_space_x1c75);
    --lay_btn_tagg_padding_side:    var(--lay_space_x1);

    /* Border */
    --lay_border_main:              var(--lay_space_1px);

    /* Radius */
    --lay_border_radius:            var(--lay_space_x03);
    --lay_border_radius_card:       var(--lay_space_x05);
    --lay_border_radius_dropdown:   var(--lay_border_radius);
    --lay_border_radius_tagg:       var(--lay_space_x1);
    --lay_border_radius_checkbox:   var(--lay_space_10p);
    --lay_border_selected_marg_rr:  var(--lay_space_3px); /* oransje venstremarg */

    /* Felt */
    --lay_felt_padding_left:        var(--lay_space_x1);
    --lay_felt_padding_right:       var(--lay_space_x1);
    --lay_felt_padding_top:         0;
    --lay_felt_padding_btm:         0;

    /* Author */
    --lay_tabell_xx_liten:          var(--lay_space_x4);
    --lay_tabell_xx_stor:           var(--lay_space_x8);

    /* Spill */
    --lay_tabell_marg_R:            var(--lay_space_x1);
    --lay_tabell_padding:           var(--lay_space_x025);


    /* ------------------------------------------ */
    /* Farger */
    /* ------------------------------------------ */

    /* ------------------------------------------ */
    /* Palette GH */
    /* ------------------------------------------ */

    --farge_black_1:                rgb(2, 4, 9); /* VSC */
    --farge_black_2:                rgb(15, 17, 22); /* (#0f1116), VSC */
    --farge_grey_1:                 rgb(23, 27, 33);
    --farge_grey_2:                 rgb(34, 38, 44); /* grey btn body */
    --farge_grey_3:                 rgb(45, 44, 45); /* (#2d2c2d), allotment borders */
    --farge_grey_4:                 rgb(49, 54, 60); /* grey btn hover */
    --farge_grey_5:                 rgb(55, 59, 64); /* grey btn border */
    --farge_grey_6:                 rgb(141, 148, 157); /* (#8d949d), grey btn hover border */
    --farge_beige_1:                rgb(166, 170, 175); /* (#cad1d8), grey btn const txt color  */
    --farge_beige_2:                rgb(242, 246, 251); /* (#f2f6fb), header txt, red btn hover color txt */
    --farge_beige_3_landing:        rgb(141, 148, 157);
    --farge_beige_2_hover:          rgb(178, 181, 186); /* (#b2b5ba) (hover grey_4) */ /* leg: header txt, red btn hover color txt */
    --farge_white_1:                white; /* (#FFFFFF) */
    --farge_blue_1:                 rgb(108, 164, 248); /* links (hover uendret), grey btn blue txt (#6CA4F8) */
    --farge_blue_1_hover:           rgb(78, 139, 204); /* */
    --farge_blue_2:                 rgb(24, 34, 56);
    --farge_blue_2_hoover:          rgb(56, 109, 227); /* blue btn body (hover helt uendret) */
    --farge_blue_3_CR:              rgb(50, 100, 215); /* (#3264d7) -> CR: Checkbox, radio  -> Gradient: rgb(49, 103, 220) -> rgb(50, 100, 215) -> rgb(46, 94, 199) */
    --farge_green_1:                rgb(67, 132, 64); /* green body btn, progress_bar */
    --farge_green_1_border:         rgb(85, 144, 83); /* green btn border */
    --farge_green_1_hover:          rgb(82, 158, 78); /* green btn hoover */
    --farge_green_1_hover_border:   rgb(98, 167, 96); /* green btn hover border */
    --farge_green_2_tagg_blur_dark: rgb(16, 47, 32);
    --farge_green_2_tagg_blur_light:rgb(161, 229, 149);
    --farge_green_2_tagg_hover:     rgb(100, 196, 75); /* darkgreen; */
    --farge_orange_1:               rgb(232, 135, 109);
    --farge_red_1:                  rgb(201, 68, 60); /* red btn hover body */
    --farge_red_2:                  rgb(229, 94, 81); /* (#E55E51), red btn txt color, red btn hoover border */
    --farge_red_2_bg:               rgb(47, 28, 31); /* Wrong password */
    --farge_red_2_border:           rgb(120, 54, 50); /* Wrong password border */
    --farge_red_2_hover:            rgb(174, 75, 66); /* (#AE4B42), Wrong password close icon hover */
    --farge_yellow_1:               yellow; /* tmp: finn riktig gulfarge */
    --farge_u_2_grey:               rgb(71, 75, 80); /* (#474b50), header txt hover, ikke offisiel, fra 0.3 opacity */
    --farge_u_3_grey:               rgb(191, 193, 197); /* Palette GOO: Ubrukt */
    --farge_u_4_grey:               rgb(221, 221, 221); /* Palette GOO: Ubrukt */
    --farge_u_5_blue_marg:          rgb(64, 112, 246);
    --farge_u_6_blue_landing:       rgb(123, 146, 176); /* DEV: Sjekk hva i landing */


    /* ------------------------------------------ */
    /* Palette VSC */
    /* ------------------------------------------ */

    --farge_black_10_editor:        rgb(14, 17, 23); /* BG, File-liste: rgb(13, 16, 21) */
    --farge_black_11_editor:        rgb(23, 27, 34); /* Active linje */
    --farge_black_12_editor:        rgb(45, 49, 57); /* File-liste selected file BG */
    --farge_grey_10_editor:         rgb(139, 148, 158); /* Linje nr. */
    --farge_grey_11_editor:         rgb(87, 99, 112); /* Selected words (samme ord) */
    --farge_grey_12_editor:         rgb(128, 128, 128); /* Fold (...) */
    --farge_beige_10_editor:        rgb(201, 209, 217); /* Vanlig txt */
    --farge_blue_10_editor:         rgb(88, 166, 255); /* Cursor */
    --farge_blue_11_editor:         rgb(122, 192, 255); /* Konstanter */
    --farge_blue_12_editor:         rgb(165, 214, 255); /* String (mørkere: rgb(163, 211, 252)) */
    --farge_blue_13_editor:         rgb(24, 52, 86); /* Markert kode */
    --farge_green_10:               rgb(53, 142, 68); /* #358E44 */
    --farge_green_11:               rgb(86, 211, 100); /* (#56D364), Terminal */
    --farge_green_12:               rgb(127, 231, 135); /* html-tags */
    --farge_pink_10_editor:         rgb(255, 123, 114); /* Editor txt */
    --farge_pink_11_editor:         rgb(212, 167, 255); /* VSC: Javascript funksjon, Modifisert: CSS-key (f.eks. color, display etc) -> VSC (samme blåfarge på alle keywords, ) */
    --farge_red_10_editor:          rgb(83, 33, 31); /* VSC: BG til opptatt fil navn alert */
    --farge_red_11_editor:          rgb(163, 40, 27); /* VSC: Border rundt opptatt fil navn alert */
    --farge_yellow_20:              rgb(255, 166, 87); /* Kode funksjoner: int(), str()*/
    --farge_yellow_21:              rgb(203, 203, 90); /* JSON curely braces, #cbcb5a */
    --farge_uu_1_grey_editor:       rgb(48, 54, 61); /* Split screen, uu er i VSC, men ikke Ace */
    --farge_uu_2_pink_editor:       rgb(248, 120, 111); /* Keywords */
    --farge_uu_3_turkis_editor:     rgb(15, 60, 64); /* Markerte clone-ord */
    --farge_uu_4_turkis_editor:     rgb(23, 90, 94); /* Markerte brackets */


    /* ------------------------------------------ */
    /* Palette primitives */
    /* ------------------------------------------ */

    --farge_blue_100:               blue; /* darkblue hover */
    --farge_blue_dark_100:          darkblue;
    --farge_blue_dark_100_hover:    var(--farge_blue_100);
    --farge_magenta_100:            magenta;
    --farge_red_100:                red;


    /* ------------------------------------------ */
    /* Palette cropper */
    /* ------------------------------------------ */

    --farge_pink_1000:              rgb(186, 136, 248); /* Editor rich (Quill) palette som jeg ikke får endret */

    /* ------------------------------------------ */
    /* Palette extra */
    /* ------------------------------------------ */

    --farge_dark_grey_subl:         rgb(48, 56, 65);
    --farge_dark_brown_safa:        rgb(43, 43, 43);


    /* ------------------------------------------ */
    /* Debug */
    /* ------------------------------------------ */

    --farge_debug:                  var(--farge_magenta_100);


    /* ------------------------------------------ */
    /* Editor kode (Ace): Kun minified, endres RT */
    /* ------------------------------------------ */

    --farge_editor_default:                 var(--farge_grey_10_editor); /* Gjeldende linje var(--farge_magenta_100) */
    --farge_editor_ingen_effekt:            transparent; /* debug > red, se README.txt > Components > editor_kode > styling, transparent Gjeldende linje, var(--farge_red_100) */
    --farge_editor_aktiv_linje:             var(--farge_black_11_editor); /* Gjeldende linje */
    --farge_editor_linjenummer:             var(--farge_grey_10_editor); /* farge tall */
    --farge_editor_linjenummer_gutter_bg:   var(--farge_black_10_editor); /* gutter bg */
    --farge_editor_kode_txt:                var(--farge_beige_10_editor); /* python: samme som funksjonsnavn */
    --farge_editor_cursor:                  var(--farge_blue_10_editor); /* gutter bg */
    --farge_editor_markert_felt:            var(--farge_blue_13_editor);
    --farge_editor_markert_parantes:        var(--farge_green_10);
    --farge_editor_aktiv_linje_gutter:      var(--farge_black_10_editor);
    --farge_editor_selected_words:          var(--farge_grey_11_editor);
    --farge_editor_keywords:                var(--farge_pink_10_editor); /* def, for, in, pass, print */
    --farge_editor_keywords_python:         var(--farge_pink_10_editor); /* def, for, in, pass, print */
    --farge_editor_keywords_javascript:     var(--farge_pink_10_editor); /* def, for, in, pass, print */
    --farge_editor_keywords_html:           var(--farge_green_12); /* html: tags */
    --farge_editor_keywords_css:            var(--farge_green_12); /* html: tags */
    --farge_editor_attributes_html:         var(--farge_blue_11_editor); /* html: tags */
    --farge_editor_attributes_css:          var(--farge_blue_11_editor); /* html: tags */
    --farge_editor_css_num:                 var(--farge_blue_11_editor); /* html: tags */
    --farge_editor_punktasjon:              var(--farge_beige_10_editor); /* . , : */
    --farge_editor_num:                     var(--farge_blue_11_editor); /* tall, konstanter */
    --farge_editor_funksjon_navn_type_def_python:   var(--farge_yellow_20); /* def, for, in, pass, print */
    --farge_editor_funksjon_keyword_javascript:     var(--farge_pink_10_editor); /* called funksjoner (f.eks. alert("blabla")) int(), str() */
    --farge_editor_funksjon_navn_def_javascript:    var(--farge_blue_11_editor); /* called funksjoner (f.eks. alert("blabla")) int(), str() */
    --farge_editor_funksjon_navn_call_javascript:   var(--farge_pink_11_editor); /* called funksjoner (f.eks. alert("blabla")) int(), str() */
    --farge_editor_key_css:                 var(--farge_pink_11_editor); /* Modifisert */
    --farge_editor_val_css:                 var(--farge_blue_10_editor); /* Modifisert, samme som theme-klasse som --farge_editor_funksjoner: int(), str(), --farge_editor_funksjoner */
    --farge_editor_fold:                    var(--farge_grey_12_editor); /* Fold (...) */
    --farge_editor_string:                  var(--farge_blue_12_editor); /*  "stringz" */
    --farge_editor_kommentar:               var(--farge_grey_10_editor); /* # Kommentar */
    --farge_editor_kode_bg:                 var(--farge_black_10_editor); /* DEV: Eneste i js-fil, gutter bg */

    /* DEV: Skal kanskje refackes til å gjelde alle */
    --farge_editor_item_border_selected:    var(--farge_blue_1); /* DEV: Marg under term selected item > rgb(248, 129, 102), --farge_pink_editor_ubrukt_11 */
    --farge_editor_kode_file_bg:            var(--farge_black_1);
    --farge_editor_kode_kode_bg:            var(--farge_black_2);
    --farge_editor_kode_term_terminal_bg:   var(--farge_black_1);
    --farge_editor_kode_term_frontend_bg:   var(--farge_black_1); /* --farge_white_1 */
    --farge_editor_kode_term_frontend_canvas_bg:    var(--farge_grey_1); /*  --farge_dark_grey_subl, --farge_dark_grey_subl, --farge_dark_brown_safa, --farge_white_1 */

    /* Icon */
    --farge_icon_hover:                     var(--farge_dev_magenta); /* var(--farge_blue_1) */

    /* Block quote */
    --farge_block_quote:                    var(--farge_magenta_100); /* var(--farge_grey_6) */

    /* DEV: Samme farge som color picker (som jeg ikke får endret nå) */
    --farge_block_quote_TMP:                var(--farge_pink_1000);


    /* ------------------------------------------ */
    /* Components */
    /* ------------------------------------------ */

    /* Developer */
    --farge_dev_magenta:                    var(--farge_magenta_100);

    /* Link */
    --farge_link_norm:                      var(--farge_blue_1);

    /* Input / Textfield */
    --farge_fokus_border:                   var(--farge_blue_2_hoover);
    --farge_input_bg:                       var(--farge_black_1);
    --farge_input_bg_fokus:                 var(--farge_black_2);
    --farge_input_bg_hover:                 var(--farge_black_2);

    /* ------------------------------------------ */
    /* Editor rich (Quill) */
    /* ------------------------------------------ */

    --farge_editor_rich_textfelt_bg:        var(--farge_input_bg);  /* var(--farge_input_bg) */
    --farge_editor_rich_textfelt_bg_hover:  var(--farge_input_bg);  /* var(--farge_input_bg) */
    --farge_editor_textfelt_rich_border:    var(--farge_border_main); /* var(--farge_black_1) */


    /* Dropdown */
    --farge_dropdown_bg:                    var(--farge_grey_1);

    /* Animations */
    --farge_circle_click_pulse:             var(--farge_blue_1); /* ORI: #f6efffa1, mui -> blå: rgb(55, 112, 196)/#376fc4, so -> blå: rgb(80, 143, 209) */

    /* Border */
    --farge_border_main:                    var(--farge_grey_4); /* --farge_grey_3 rgb(45, 44, 45), #2d2c2d; alt: --farge_border_4 */
    --farge_border_main_tmp:                var(--farge_grey_3);

    /* Header */
    --farge_header_bg:                      var(--farge_grey_1);

    /* Splitter (Allotment override fac) */
    /* Factory custom-settings (overstyres) */
    --focus-border:                         var(--farge_fokus_border); /* Overstyrt (splitter.css), #007fd4; */
    --separator-border:                     transparent; /* Overstyrt (splitter.css), #838383; */
    /* Egne custom-settings */
    --farge_splitter_border:                var(--farge_border_main);

    /* Cropper */
    --farge_cropper_main:                   var(--farge_beige_1);
    --farge_cropper_modal_bg:               transparent;
    --farge_cropper_bilde_box_ramme:        transparent;
    --farge_cropper_bilde_box_ramme_bg_wrapper:     transparent;
    --farge_cropper_bilde_box_sirkel:               var(--farge_beige_1);
    --farge_cropper_bilde_box_siktet_i_midten:      var(--farge_cropper_main);
    --farge_cropper_bilde_box_filter:               var(--farge_beige_1);; /* var(--farge_white_1) */
    --farge_cropper_bilde_box_grabbers_main:        var(--farge_beige_1);
    --farge_cropper_bilde_box_grabbers_e:           var(--farge_cropper_bilde_box_grabbers_main);
    --farge_cropper_bilde_box_grabbers_n:           var(--farge_cropper_bilde_box_grabbers_main);
    --farge_cropper_bilde_box_grabbers_w:           var(--farge_cropper_bilde_box_grabbers_main);
    --farge_cropper_bilde_box_grabbers_s:           var(--farge_cropper_bilde_box_grabbers_main);
    --farge_cropper_bilde_box_grabbers_ne:          var(--farge_cropper_bilde_box_grabbers_main);
    --farge_cropper_bilde_box_grabbers_nw:          var(--farge_cropper_bilde_box_grabbers_main);
    --farge_cropper_bilde_box_grabbers_sw:          var(--farge_cropper_bilde_box_grabbers_main);
    --farge_cropper_bilde_box_grabbers_se:          var(--farge_fokus_border);

    /* Alert */
    --farge_alert_fail_bg:                  var(--farge_red_2_bg);
    --farge_alert_success_bg:               var(--farge_green_2_tagg_blur_dark); /* rgb(25, 64, 13); */
    --farge_alert_success_border:           var(--farge_green_1);
    /* farge_alert_success_icon -> #438440, --farge_green_1:            rgb(67, 132, 64) */
    /* farge_alert_success_icon_hover -> #529E4E --farge_green_1_hover: rgb(82, 158, 78) */

    /* Splitter */
    --farge_splitter:                       #007fd4;

    /* Gradient */
    --farge_grad_1:                         #00f260;
    --farge_grad_2:                         #0575e6;

    --farge_grad_1:                         rgb(150, 95, 209);
    --farge_grad_2:                         rgb(134, 26, 175);

    --farge_grad_1:                         white;
    --farge_grad_2:                         #0575e6;


    /* ------------------------------------------ */
    /* App.js */
    /* ------------------------------------------ */

    --farge_app_body:                       transparent; /* debug: var(--farge_debug) */


    /* ------------------------------------------ */
    /* Pages */
    /* ------------------------------------------ */

    /* Page core */
    --farge_page_core:                      transparent; /* transparent, debug: green */


    /* ------------------------------------------ */
    /* Doc */
    /* ------------------------------------------ */

    /* Blokk */
    --farge_blokk_bg:                       transparent; /* transparent, debug: blue */


    /* ------------------------------------------ */
    /* Spill */
    /* ------------------------------------------ */

    --farge_uttrykk:                        var(--farge_beige_1);
    --farge_uttrykk_svar:                   var(--farge_beige_1);
    --farge_uttrykk_riktig:                 var(--farge_green_11);
    --farge_uttrykk_feil:                   var(--farge_red_1);


    /* ------------------------------------------ */
    /* Opacity */
    /* ------------------------------------------ */

    --opacity_main:                         0.96; /* 0.96: Diskret */
    --opacity_header:                       0.96;
    --opacity_dropdown:                     0.96;
    --opacity_ani:                          0.5;
    --opacity_circle_click:                 0.5;
    --opacity_landing_card:                 0.90;


    /* ------------------------------------------ */
    /* z-index */
    /* ------------------------------------------ */

    --z_index_hidden:                       -1;
    --z_index_1:                            1;
    --z_index_clock_rotation:               1;
    --z_index_editor_rich_picker:           2;
    --z_index_editor_rich_toolbar_picker:   2;
    --z_index_splitter:                     6; /* 6 */
    --z_index_path_wrapper:                 8;
    --z_index_header_dropdown_ch:           9; /* 6, 9; Også desktop dropdown */
    --z_index_header_mobile:                7; /* 9; */
    --z_index_black_screen_overlay:         10;
    --z_index_dekker_init:                  10;


    /* ------------------------------------------ */
    /* Time (FX) */
    /* ------------------------------------------ */
    --time_circle_click:                    0.5s;

    /* Splitter: Transitions og ani for vertikal navn lbl */
    --time_transition_btn:                  0.3s; /* Bruk på alle btns */
    --time_transition_border_btm:           0.1s;
    --time_ani_fade_out:                    0.2s;
    --time_ani_fade_in:                     3s;
    --time_ani_clock:                       0.5s;
    --time_ani_clock_anti:                  0.5s;
    --time_ani_fade_in_landing:             0.5s;
    --time_ani_fade_in_landing_delay_1:     1s;
    --time_ani_fade_in_landing_delay_2:     1.5s;
    --time_ani_fade_in_landing_page_core:   1s; /* 0.3 */


    /* ------------------------------------------ */
    /* Scale */
    /* ------------------------------------------ */

    --scale_ani_transform:                  2;
    --scale_circle_click_transform:         var(--scale_ani_transform);
}


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
