/* ------------------------------------------ */
/* Strek */
/* ------------------------------------------ */

.strek_header {
    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             var(--lay_strek_yy);
    width:              100%;

    /* Frame */
    margin-top:         0;
    margin-bottom:      0;

    /* Color */
    color:              transparent;
    background-color:   var(--farge_border_main);
}

.strek_header_ch {
    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             var(--lay_strek_yy);
    width:              100%;

    /* Frame
    margin-top: var(--lay_space_x05);
    margin-bottom: 0;  */

    /* Color */
    color:              transparent;
    background-color:   var(--farge_border_main);
}

.strek_footer {
    /* Display */
    display:            flex;
    flex-direction:     column;
    justify-content:    flex-start;
    align-items:        flex-start;
    height:             var(--lay_strek_yy);
    width:              90%;
    overflow:           hidden;

    /* Frame */
    margin-top:         0;
    margin-bottom:      0;
    margin-left:        0;
    margin-right:       0;

    /* Color */
    color:              transparent;
    background-color:   var(--farge_border_main);
}


/* ------------------------------------------ */
/* FX */
/* For evt. splitter text rotering */
/* - DEV: Husk alle webkits */
/* ------------------------------------------ */

.fade_out {
    /* Ani */
    animation:          fade_out_effect var(--time_ani_fade_out) linear forwards;
    -ms-animation:      fade_out_effect var(--time_ani_fade_out) linear forwards;
    -webkit-animation:  fade_out_effect var(--time_ani_fade_out) linear forwards;
    animation-iteration-count: 1;

    /* Color */
    opacity: 1;
}

/*
@keyframes fade_out_effect {
    0%      { opacity: 1; }
    5%      { opacity: 1; }
    10%     { opacity: 1; }
    25%     { opacity: 1; }
    30%     { opacity: 1; }
    80%     { opacity: 0; }
    100%    { opacity: 0; }
}
*/

.fade_in {
    /* Ani */
    animation:          fade_in_effect var(--time_ani_fade_in) linear forwards;
    -ms-animation:      fade_in_effect var(--time_ani_fade_in) linear forwards;
    -webkit-animation:  fade_in_effect var(--time_ani_fade_in) linear forwards;
    animation-iteration-count: 1;

    /* Color */
    opacity: 0;
}

/* Pause
@keyframes fade_in_effect {
    0% { opacity: 0; }
    5% { opacity: 0; }
    10% { opacity: 1; }
    25% { opacity: 1; }
    30% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 1; }
}
*/

.clock_custom_kode {
    /* Display */
    display:            flex;
    overflow:           hidden;
    position:           relative; /* absolute; */
    z-index:            var(--z_index_clock_rotation);

    /* Ani */
    animation:          clock_custom_kode var(--time_ani_clock) linear forwards; /* infinite 0s; */
    animation-iteration-count:      1;
}
@keyframes clock_custom_kode {
    /* Clock custom effect animation */
    0% {
        top:            var(--lay_rotering_top);
        left:           -0.0rem;}
    10% {
        top:            var(--lay_rotering_top);
        left:           -0.5rem;}
    20% {
        top:            var(--lay_rotering_top);
        left:           -0.75rem;}
    75% {
        top:            var(--lay_rotering_top);
        left:           -1.0rem;}
    100% {
        top:            var(--lay_rotering_top);
        left:           -1.0rem;
        -webkit-transform:      rotate(90deg);}
}

.clock_custom_anti_kode {
    /* Display */
    display:            flex;
    position:           relative; /* absolute; */
    overflow:           hidden;
    z-index:            var(--z_index_clock_rotation);

    /* Ani */
    animation:          clock_custom_anti_kode var(--time_ani_clock_anti) linear forwards; /* infinite 0s; */
    animation-iteration-count:      1;
}
@keyframes clock_custom_anti_kode {
    /* Clock custom effect animation */
    0% {
        top:            var(--rotering_top_anti);
        left:           -1.0rem;
        -webkit-transform:      rotate(90deg);}
    10% {
        top:            var(--rotering_top_anti);
        left:           -1.0rem;}
    20% {
        top:            var(--rotering_top_anti);
        left:           -0.75rem;}
    75% {
        top:            var(--rotering_top_anti);
        left:           -0.5rem;}
    100% {
        top:            var(--rotering_top_anti);
        left:           -0.0rem;}
}

.clock_custom_term {
    /* Display */
    display:            flex;
    overflow:           hidden;
    position:           absolute;
    z-index:            var(--z_index_clock_rotation);

    /* Ani */
    animation:          clock_custom_term var(--time_ani_clock) linear forwards; /* infinite 0s; */
    animation-iteration-count:      1;
}
@keyframes clock_custom_term {
    /* Clock custom effect animation */
    0% {
        top:            calc(var(--lay_header_yy) + 0.0rem);
        right:          -0.0rem;}
    10% {
        top:            calc(var(--lay_header_yy) + 1.0rem);
        right:          -0.5rem;}
    20% {
        top:            calc(var(--lay_header_yy) + 1.4rem);
        right:          -1.0rem;}
    75% {
        top:            calc(var(--lay_header_yy) + 1.4rem);
        right:          -1.5rem;}
    100% {
        top:            calc(var(--lay_header_yy) + 1.4rem); /* QF, DEV: 2.7rem Lag height på alle bokser */
        right:          -2.0rem;
        -webkit-transform:      rotate(90deg);}
}

.clock_custom_anti_term {
    /* Display */
    display:            flex;
    position:           absolute;
    overflow:           hidden;
    z-index:            var(--z_index_clock_rotation);

    /* Ani */
    animation:          clock_custom_anti_term var(--time_ani_clock_anti) linear forwards; /* infinite 0s; */
    animation-iteration-count:      1;
}
@keyframes clock_custom_term_anti {
    /* Clock custom effect animation */
    0% {
        top:                calc(var(--lay_header_yy) - 1.4rem); /* QF, DEV: 2.7rem Lag height på alle bokser */
        right:              -2.0rem;
        -webkit-transform:  rotate(90deg);}
    10% {
        top:                calc(var(--lay_header_yy) - 1.4rem);
        right:              -1.5rem;}
    20% {
        top:                calc(var(--lay_header_yy) - 1.4rem);
        right:              -1.0rem;}
    75% {
        top:                calc(var(--lay_header_yy) - 1.0rem);
        right:              -0.5rem;}
    100% {
        top:                calc(var(--lay_header_yy) - 0.0rem);
        right:              -0.0rem;}
}

/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */


.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }

  .left-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ddd;
  }

  .center-col {
    flex: 1;
    background: #aaa;
    overflow-y: scroll !important;
  }

  .right-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e7e7e7;
  }