/* ------------------------------------------ */
/* Btn col */
/* ------------------------------------------ */

.btn_trans_white {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    position:           absolute;
    top:                0;
    right:              0;

    /* Frame */
    margin-left:        auto;
    margin-top:         var(--lay_space_x1);
    margin-right:       var(--lay_space_x1);
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_white_1);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   transparent;
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

@font-face {
    font-family:    "matasy_font_1";
	src:            url("../fonts/red-glass-font/RedGlass-BWx23.ttf");

    /*
    "../fonts/odins-spear-font/OdinsSpearRaggedHollow-Pq8B.ttf"
    "../fonts/suggested/Suggested3D.ttf"
    "../fonts/floki/floki-Hard.ttf"
    "../fonts/prida-em-cooper-font/Pridaemcooper-RpyMV.ttf"
    "../fonts/maskneyes-font/MaskneyesDemo-qZPar.otf"
    */

}

.btn_download_box {
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);
}

.btn_download_box:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_grey_6);

    /* Color */
    background-color:   var(--farge_grey_2);
}

.btn_download {
    /* Display */
    display:            flex;
    flex-direction:     column; /* row */
    justify-content:    center; /* flex-start */
    align-items:        center;
    height:             var(--lay_space_x2);

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);

    /* */



    /* Txt
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;


    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    */

    /* Color  */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_white_1);
    font-family:                "matasy_font_1"; /* var(--lay_font)  */
    font-size:                  1rem;


    /* Image Background */
    background:                 url("../img/bg/_font_bg.png");
    background-size:            cover;
    -webkit-background-clip:    text;               /* Necessary for the image to clip to the text */
    background-clip:            text;               /* Clip the background to the text */
    color:                      transparent;        /* Make the text color transparent so the image shows through */



    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}


.btn_download_pause:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_grey_6);

    /* Color */
    background-color:   var(--farge_grey_4);
}

.btn_grey {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_space_x2);

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_grey:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_grey_6);

    /* Color */
    background-color:   var(--farge_grey_4);
}

.btn_grey_100 {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_white_1);
}
.btn_grey_100:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_grey_6);

    /* Color */
    background-color:   var(--farge_grey_4);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_grey_100_no_hover {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_white_1);
}

.btn_green_resp {
    /* Display */
    display:            flex;
    flex-direction:     row;
    align-items:        center;
    height:             var(--lay_space_x2);

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_green_1_border);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_green_1);
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_green_resp:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_green_1_hover_border);

    /* Color */
    background-color:   var(--farge_green_1_hover);
}

.btn_green_100 {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_green_1_border);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_green_1);
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_green_100:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_green_1_hover_border);

    /* Color */
    background-color:   var(--farge_green_1_hover);
}

.btn_red {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_space_x2);

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_5);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_red_2); /* Note: uendret fra grey btn */

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_red:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_red_2);

    /* Color */
    color:              var(--farge_beige_2);
    background-color:   var(--farge_red_1);
}

.btn_red_trans_ugyldig {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              max-content; /* DEV: På alle? */

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_red_1);
}

.btn_red_trans_100_ugyldig {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_red_1);
}

.btn_red_100 {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_5);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_red_2); /* Note: uendret fra grey btn */

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_red_100:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_red_2);

    /* Color */
    color:              var(--farge_beige_2);
    background-color:   var(--farge_red_1);
}

.btn_magenta_100 {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_dev_magenta);
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.btn_blue {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_blue_dark_100);
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_blue:hover {
    background-color:   var(--farge_blue_dark_100_hover);
}

.btn_blue_100 {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_blue_dark_100);
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_blue_100:hover {
    background-color:   var(--farge_blue_dark_100_hover);
}

/* ------------------------------------------ */
/* Btn tagg */
/* ------------------------------------------ */

.btn_tagg_filter {
    /* Display
       - Kun height er endret fra color btn display (default er kanskje auto)
    */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_btn_tagg_yy);

    /* Frame */
    padding:            var(--lay_space_x05); /* lik padding */
    padding-left:       var(--lay_btn_tagg_padding_side); /* Alternativ */
    padding-right:      var(--lay_btn_tagg_padding_side); /* Alternativ */
    border:             0 solid transparent; /* Note: Gjøres mindre */
    border-radius:      var(--lay_border_radius_tagg);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_tagg_size);
    font-weight:        var(--lay_font_tagg_weight);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2); /* var(--farge_blue_2); */
    color:              var(--farge_beige_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_tagg_filter:hover {
    /* Color */
    background-color:   var(--farge_green_2_tagg_hover); /* var(--farge_blue_2_hoover); */
    background-color:   var(--farge_grey_4);
    color:              var(--farge_white_1);
}
.btn_tagg_filter_selected {
    /* Display
       - Kun height er endret fra color btn display (default er kanskje auto)
    */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_btn_tagg_yy);

    /* Frame */
    padding:            var(--lay_space_x05); /* lik padding */
    padding-left:       var(--lay_btn_tagg_padding_side); /* Alternativ */
    padding-right:      var(--lay_btn_tagg_padding_side); /* Alternativ */
    border:             0 solid transparent; /* Note: Gjøres mindre */
    border-radius:      var(--lay_border_radius_tagg);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_tagg_size);
    font-weight:        var(--lay_font_tagg_weight);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_5); /* var(--farge_blue_2); */
    color:              var(--farge_beige_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_tagg_filter_selected:hover {
    /* Color */
    color:              var(--farge_beige_2);
}

.btn_tagg_fag {
    /* Display
       - Kun height er endret fra color btn display (default er kanskje auto)
    */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_btn_tagg_yy);
    min-height:         var(--lay_btn_tagg_yy);
    max-height:         var(--lay_btn_tagg_yy);

    /* Frame */
    padding:            var(--lay_space_x05); /* lik padding */
    padding-left:       var(--lay_btn_tagg_padding_side); /* Alternativ */
    padding-right:      var(--lay_btn_tagg_padding_side); /* Alternativ */
    border:             0 solid transparent; /* Note: Gjøres mindre */
    border-radius:      var(--lay_border_radius_tagg);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_tagg_size);
    font-weight:        var(--lay_font_tagg_weight);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_green_2_tagg_blur_dark); /* var(--farge_blue_2); */
    color:              var(--farge_green_2_tagg_blur_light);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_tagg_fag:hover {
    /* Color */
    background-color:   var(--farge_green_2_tagg_hover); /* var(--farge_blue_2_hoover); */
    color:              var(--farge_white_1);
}
.btn_tagg_fag_selected {
    /* Display
       - Kun height er endret fra color btn display (default er kanskje auto)
    */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_btn_tagg_yy);
    min-height:         var(--lay_btn_tagg_yy);
    max-height:         var(--lay_btn_tagg_yy);

    /* Frame */
    padding:            var(--lay_space_x05); /* lik padding */
    padding-left:       var(--lay_btn_tagg_padding_side); /* Alternativ */
    padding-right:      var(--lay_btn_tagg_padding_side); /* Alternativ */
    border:             0 solid transparent; /* Note: Gjøres mindre */
    border-radius:      var(--lay_border_radius_tagg);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_tagg_size);
    font-weight:        var(--lay_font_tagg_weight);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_green_2_tagg_hover); /* var(--farge_blue_2_hoover); */
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.btn_tagg_lang {
    /* Display
       - Kun height er endret fra color btn display (default er kanskje auto)
    */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_btn_tagg_yy);
    min-height:         var(--lay_btn_tagg_yy);
    max-height:         var(--lay_btn_tagg_yy);

    /* Frame */
    padding:            var(--lay_space_x05); /* lik padding */
    padding-left:       var(--lay_btn_tagg_padding_side); /* Alternativ */
    padding-right:      var(--lay_btn_tagg_padding_side); /* Alternativ */
    border:             0 solid transparent; /* Note: Gjøres mindre */
    border-radius:      var(--lay_border_radius_tagg);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_tagg_size);
    font-weight:        var(--lay_font_tagg_weight);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_blue_2);
    color:              var(--farge_blue_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_tagg_lang:hover {
    /* Color */
    background-color:   var(--farge_blue_2_hoover);
    color:              var(--farge_white_1);
}
.btn_tagg_lang_selected {
    /* Display
       - Kun height er endret fra color btn display (default er kanskje auto)
    */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    height:             var(--lay_btn_tagg_yy);
    min-height:         var(--lay_btn_tagg_yy);
    max-height:         var(--lay_btn_tagg_yy);

    /* Frame */
    padding:            var(--lay_space_x05); /* lik padding */
    padding-left:       var(--lay_btn_tagg_padding_side); /* Alternativ */
    padding-right:      var(--lay_btn_tagg_padding_side); /* Alternativ */
    border:             0 solid transparent; /* Note: Gjøres mindre */
    border-radius:      var(--lay_border_radius_tagg);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_tagg_size);
    font-weight:        var(--lay_font_tagg_weight);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_blue_2_hoover);
    color:              var(--farge_white_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}


/* ------------------------------------------ */
/* Btn tagg */
/* ------------------------------------------ */

.btn_filter_100 {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    align-items:        center;
    height:             var(--lay_space_x2);
    width:              100%;

    /* Frame */
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border:             var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight_bold);
    text-decoration:    none;

    /* Color */
    background-color:   var(--farge_grey_2);
    color:              var(--farge_beige_1);
}
.btn_filter_100:hover {
    /* Frame */
    border:             var(--lay_border_main) solid var(--farge_grey_6);

    /* Color */
    background-color:   var(--farge_grey_4);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}


/* ------------------------------------------ */
/* Btn trans (account_nav, profil_kategorier) */
/* ------------------------------------------ */

.btn_trans_100 {
    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;

    /* Frame */
    margin-right:       auto;
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      0;
    padding-left:       0;
    border-radius:      var(--lay_border_radius);

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight);
    text-decoration:    none;

    /* Color */
    background-color:   transparent;
    color:              var(--farge_beige_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.btn_trans_border_100 {
    /* Ubrukt */

    /* Display */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    width:              100%;

    /* Frame */
    margin-right:           auto;
    padding-top:            var(--lay_space_x05);
    padding-bottom:         var(--lay_space_x05);
    padding-right:          var(--lay_space_x1);
    padding-left:           var(--lay_space_x1);
    border-radius:          var(--lay_border_radius);
    border:                 var(--lay_border_main) solid var(--farge_grey_2);
    border-bottom-width:    0;

    /* Txt */
    font-family:            var(--lay_font);
    font-size:              var(--lay_font_norm_size);
    font-weight:            var(--lay_font_norm_weight);
    text-decoration:        none;

    /* Color */
    background-color:       transparent;
    color:                  var(--farge_beige_1);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.btn_trans_border_100:hover {
    /* Color */
    background-color:       var(--farge_grey_1) !important;
}
.btn_trans_border_100:first-child {
    /* Sjekk: Ikke lengre brukt */

    /* Frame */
    border-top-right-radius:    var(--lay_border_radius);
    border-top-left-radius:     var(--lay_border_radius);

    /* Cursor */
    cursor:                 default;
    pointer-events:         visible;
}
.btn_trans_border_100:first-child:hover {
    /* Sjekk: Ikke lengre brukt */

    /* Color */
    background-color:       transparent !important;
}
.btn_trans_border_100:last-child {
    /* Sjekk: Ikke lengre brukt */

    /* Frame */
    border-bottom-width:        var(--lay_border_main);
    border-top-left-radius:     0;
    border-bottom-right-radius: var(--lay_border_radius);
    border-bottom-left-radius:  var(--lay_border_radius);
}

.btn_trans_profil_disp_kategori {
    /* Display
        - flex-shrink: 0; -> Scroll istendefor krymping
    */
    display:            flex;
    flex-direction:     row;
    justify-content:    flex-start;
    align-items:        center;
    gap:                var(--lay_space_x05);

    /* Frame */
    margin-right:       auto;
    padding-top:        var(--lay_space_x05);
    padding-bottom:     var(--lay_space_x05);
    padding-right:      var(--lay_space_x1);
    padding-left:       var(--lay_space_x1);
    border-top:         0;
    border-left:        0;
    border-right:       0;

    /* Txt */
    font-family:        var(--lay_font);
    font-size:          var(--lay_font_norm_size);
    font-weight:        var(--lay_font_norm_weight);
    text-decoration:    none;

    /* Color */
    background-color:   transparent;
    color:              var(--farge_beige_1);

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}
.btn_trans_profil_disp_kategori_unselected {
    /* Frame */
    border-bottom:      var(--lay_border_selected_marg_rr) solid transparent;
}
.btn_trans_profil_disp_kategori_unselected:hover {
    /* Frame */
    border-bottom:      var(--lay_border_selected_marg_rr) solid var(--farge_grey_4) !important;
}
.btn_trans_profil_disp_kategori_selected {
    /* Frame */
    border-bottom:      var(--lay_border_selected_marg_rr) solid var(--farge_orange_1);
}
.btn_trans_profil_disp_kategori_selected:hover {
    /* Frame */
    border-bottom:      var(--lay_border_selected_marg_rr) solid var(--farge_orange_1) !important;
}


/* ------------------------------------------ */
/* Reponsive > Søkeord > CSS_Media */
/* ------------------------------------------ */

/* Mobile 2 */
@media (min-width: 0px) {

    .btn_green_resp {

        /* Display */
        justify-content:    center;
        width:              100%; /* DEV: max-content på alle? */

    }

}

/* Mobile 1, Desktop */
@media (min-width: 530px) {

    .btn_green_resp {

        /* Display */
        justify-content:    flex-start;
        width:              auto; /* DEV: max-content på alle? */

    }

}


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
