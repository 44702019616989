/* ------------------------------------------ */
/* Profil */
/* ------------------------------------------ */

.bilde_profil_mini {

    /* Display */
    width:              var(--lay_img_rr_mini);
    height:             var(--lay_img_rr_mini);
    flex-shrink:        0;

    /* Frame */
    border:             solid var(--lay_border_main) var(--farge_grey_4);
    border-radius:      50%; /* 50 % */

    /* Cursor */
    cursor: pointer;
    pointer-events:     visible;
}

.bilde_profil_mikro {

    /* Display */
    width:              var(--lay_img_rr_mikro);
    height:             var(--lay_img_rr_mikro);
    flex-shrink:        0;

    /* Frame */
    border:             solid var(--lay_border_main) var(--farge_grey_4);
    border-radius:      50%; /* 50 % */

    /* Cursor */
    cursor: pointer;
    pointer-events:     visible;
}

.bilde_profil_liten {

    /* Display */
    width:              var(--lay_img_rr_liten);
    height:             var(--lay_img_rr_liten);
    flex-shrink:        0;

    /* Frame */
    border:             solid var(--lay_border_main) var(--farge_grey_4);
    border-radius:      50%;

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.bilde_profil_norm {

    /* Display */
    width:              var(--lay_img_rr_norm);
    height:             var(--lay_img_rr_norm);
    flex-shrink:        0;

    /* Frame */
    border:             solid var(--lay_border_main) var(--farge_grey_4);
    border-radius:      50%;

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.bilde_profil_medium {

    /* Display */
    width:              var(--lay_img_rr_medium);
    height:             var(--lay_img_rr_medium);
    flex-shrink:        0;

    /* Frame */
    border:             solid var(--lay_border_main) var(--farge_grey_4);
    border-radius:      50%;

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}

.bilde_profil_stor {

    /* Display */
    width:              var(--lay_img_rr_stor);
    height:             var(--lay_img_rr_stor);
    flex-shrink:        0;

    /* Frame */
    border:             solid var(--lay_border_main) var(--farge_grey_4);
    border-radius:      50%;

    /* Color */
    background-color:   transparent;

    /* Cursor */
    cursor:             pointer;
    pointer-events:     visible;
}


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
