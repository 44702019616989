/* ------------------------------------------ */
/* Img */
/* ------------------------------------------ */


.flex_doc_img_top {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto;
    overflow:               hidden;
    align-self:             center;

    /* Frame */
    border:                 var(--lay_border_main) solid transparent; /* var(--farge_border_main) */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       transparent;
}

.flex_doc_icon_top {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            center;
    width:                  100%;
    height:                 auto;
    height:                 auto;           /* 12 rem*/
    overflow:               hidden;

    /* Frame */
    margin-top:             var(--lay_icon_forside_top_yp);

    /* Color */
    background-color:       transparent;
}


.flex_img {

    /* Display */
    display:                flex;
    flex-direction:         column;
    justify-content:        flex-start;
    align-items:            flex-start;
    width:                  100%;
    height:                 auto;
    overflow:               hidden;
    align-self:             center;

    /* Frame */
    border:                 var(--lay_border_main) solid transparent; /* var(--farge_border_main) */
    border-radius:          var(--lay_border_radius);

    /* Color */
    background-color:       transparent;
}

/* ------------------------------------------ */
/* Logo */
/* ------------------------------------------ */

.logo_header_desktop {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/logo/logo.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            0px; /* var(--lay_space_x05) */
    margin-right:           var(--lay_space_x05);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.logo_header_desktop:hover {

    /* Display */
    background:             url("../img/logo/logo_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}

.logo_header_mobile {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/logo/logo.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.logo_header_mobile:hover {

    /* Display */
    background:             url("../img/logo/logo_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}

.logo_footer {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/logo/logo_footer.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    cursor:                 pointer;
    pointer-events:         visible;
    */

    /* Hvis hover
    .logo_footer:hover {

        Display > Hoover-tricks for ikke å endre farge når hovres over (samme som vanlig farge)
        background:             url("../img/logo/logo_footer_hover.svg");
        background-size:        100%;
        flex-shrink:            0;
    }
    */
}

.logo_reg {

    /* Display */
    width:                  var(--lay_space_x4);
    height:                 var(--lay_space_x4);
    background:             url("../img/logo/logo.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.logo_reg:hover {

    /* Display */
    background:             url("../img/logo/logo_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}


/* ------------------------------------------ */
/* Icon wrapper (position) */
/* ------------------------------------------ */

.inline_icon_wrapper {

    /* Søkeord > icon_xp */

    /* Display */
    display:                inline;
    min-width:              var(--lay_icon_wrapper_xy);
    max-width:              var(--lay_icon_wrapper_xy);

    /* Color */
    background-color:       transparent; /* DEBUG_HEADER: darkgreen */
}

.inline_flex_icon_wrapper {

    /* Display */
    display:                inline-flex; /* alternative: inline-block */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    transform:              translateY(var(--lay_space_1px)); /* Custom */
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.inline_flex_icon_marg_left_wrapper {

    /* Note:
       - Brukes sammen med kom i quest
       - Går sammen med &nbsp; -> Kan tweake det til uten marg/&nbsp
    */

    /* Display */
    display:                inline-flex; /* alternative: inline-block */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    transform:              translateY(var(--lay_space_1px)); /* Custom */
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_space_x05);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.position_abs_icon_wrapper {

    /* Display */
    position:               absolute;
    top:                    0;
    right:                  0;

    /* Frame */
    margin-top:             var(--lay_space_x05);
    margin-right:           var(--lay_space_x05);

    /* Color */
    background-color:       transparent;
}

/* ------------------------------------------ */
/* TMP > Items */
/* ------------------------------------------ */

.icon_menu_book_TMP {

    /* ORI > Google > menu_book_black_24dp.svg (Rounded?) */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/_menu_book_TMP.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_computer_TMP {

    /* ORI > Google > Rounded? > computer_black_24dp*/

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/_computer_TMP.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Menu */
/* ------------------------------------------ */

.icon_menu_burger_beige_2_norm_24_24 {

    /* ORI > Google: menu_black_24dp > DEV: Rounded ? */

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/menu_burger_beige_2.svg");
    background-size:        100%;
    flex-shrink:            0;
}
.icon_menu_burger_beige_2_norm_24_24:hover {

    /* Display */
    background:             url("../img/icon/menu_burger_beige_2_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}


/* ------------------------------------------ */
/* Mapper */
/* ------------------------------------------ */

.icon_arrow_collapsed_beige_2_norm_48_12 {

    /* - Note: */
    /*   - ORI > Google: arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg */
    /*   - Hover i 01_header.css */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_beige_2.svg");
    background-size:        100%;
    flex-shrink:            0;
}
.icon_arrow_collapsed_beige_2_norm_48_12:hover {

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_beige_2_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}
.icon_arrow_collapsed_beige_2_hoverless_norm_48_12 {

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_beige_2.svg");
    background-size:        100%;
    flex-shrink:            0;
}
.icon_arrow_collapsed_grey_norm_48_12 {

    /* ORI: Google: arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg */
    /* Note:
       - Hover i 01_header.css */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_grey.svg");
    background-size:        100%;
    flex-shrink:            0;
}
.icon_arrow_collapsed_grey_norm_48_12:hover {
    /*
       - Note
       - ORI > Google > arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg
       - Hover i 01_header.css */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;
}
.icon_arrow_collapsed_grey_hoverless_norm_48_12 {

    /* ORI: Google: arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg */

    /* Note:
       - Hover i 01_header.css */

       /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/arrow_collapsed_grey.svg");
    background-size:        100%;
    flex-shrink:            0;
}

.icon_arrow_expanded_beige_2_norm_48_20 {
    /*
    Note:
    - ORI: Google: expand_more_FILL0_wght400_GRAD0_opsz48.svg
    - Ekstra: Marg
    - Hover i 01_header.css
    - DEV: Sjekk om rounded?
    */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/arrow_expanded_beige_2.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_icon_wrapper_marg_xp);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_arrow_expanded_beige_2_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/arrow_expanded_beige_2_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_icon_wrapper_marg_xp);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_arrow_expanded_grey_norm_48_20 {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/arrow_expanded_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_icon_wrapper_marg_xp);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_arrow_expanded_grey_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/arrow_expanded_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_icon_wrapper_marg_xp);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_arrow_expanded_grey_hoverless_norm_48_20 {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/arrow_expanded_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    margin-left:            var(--lay_icon_wrapper_marg_xp);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* View */
/* ------------------------------------------ */

.icon_view_horizontal_grey_norm_48_20 {

    /* ORI > Google > Rounded Y > view_week_FILL0_wght400_GRAD0_opsz48.svg */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/view_horizontal_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_view_horizontal_grey_norm_48_20:hover {

    /* ORI > Google > Rounded Y > view_week_FILL0_wght400_GRAD0_opsz48.svg */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/view_horizontal_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_view_vertical_grey_norm_48_20 {

    /* ORI > Google > Rounded Y > view_week_FILL0_wght400_GRAD0_opsz48.svg */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/view_vertical_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_view_vertical_grey_norm_48_20:hover {

    /* ORI > Google > Rounded Y > view_week_FILL0_wght400_GRAD0_opsz48.svg */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/view_vertical_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}



/* ------------------------------------------ */
/* Close / Exit */
/* ------------------------------------------ */

.icon_close_grey_liten_24_16 {

    /* ORI: Google > Rounded? > close_black_24dp.svg */
    /* Alternativ: 24px (--lay_space_x1c5) */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/close_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_close_grey_liten_24_16:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/close_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_close_green_liten_24_16 {

    /* ORI: Google > Rounded? > close_black_24dp.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/close_green.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_close_green_liten_24_16:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/close_green_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_close_red_liten_24_16 {

    /* ORI: Google > Rounded? > close_black_24dp.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/close_red.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_close_red_liten_24_16:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/close_red_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


.icon_close_grey_medium_24_16 {

    /* ORI: Google > Rounded? > close_black_24dp.svg */
    /* Alternativ: 24px (--lay_space_x1c5) */

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/close_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    translate:              -0.3rem -0.3rem;; /* Custom */



    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_close_grey_liten_24_16:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/close_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Edit */
/* ------------------------------------------ */

.icon_edit_grey_liten_24_16 {

    /* ORI: Google > Rounded? > edit_black_24dp.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/edit_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_edit_grey_liten_24_16:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/edit_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Fil */
/* ------------------------------------------ */

.icon_upload_cloud_grey_medium_24_32 {

    /* ORI > Google > Rounded? > cloud_upload_black_24dp.svg */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/upload_cloud_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_upload_cloud_grey_medium_24_32:hover {

    /* Display */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/upload_cloud_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_upload_circle_grey_norm_48_20 {

    /* ORI > Google > Rounded > arrow_circle_up_FILL0_wght400_GRAD0_opsz48.svg */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/upload_circle_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_upload_circle_grey_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/upload_circle_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_download_blue_liten_24_16 {

    /* ORI > Google > Rounded? > save_alt_black_24dp.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/download_blue.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_download_blue_liten_24_16:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/download_blue_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Profil > Account: FIN SIZE */
/* ------------------------------------------ */

.icon_account_profil_circle_grey_norm_48_20 {

    /* ORI > Google > Rounded > verified_user_FILL0_wght400_GRAD0_opsz48.svg */
    /* GH > Samme farge > Rund figur, uten sirkel > Ingen hover */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_profil_circle_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_account_profil_circle_grey_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_profil_circle_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_account_profil_circle_grey_hoverless_norm_48_20 {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_profil_circle_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_account_shield_grey_norm_48_20 {

    /* ORI > Google > Rounded > verified_user_FILL0_wght400_GRAD0_opsz48.svg */
    /* GH grey > Lignende skjold, men med nøkkel */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_shield_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_account_shield_grey_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_shield_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_account_shield_grey_hoverless_norm_48_20 {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_shield_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_account_human_lang_grey_hoverless_norm_48_20 {

    /* ORI > Google > Rounded > language_FILL0_wght400_GRAD0_opsz48 */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_human_lang_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_account_logout_grey_norm_48_20 {

    /* ORI > Google > Rounded > logout_FILL0_wght400_GRAD0_opsz48.svg */
    /* GH grey > Samme ikon > Også i header som beige > DEV: Sjekk om skal ha Google icons i alle */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_logout_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_account_logout_grey_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_logout_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_account_logout_grey_hoverless_norm_48_20 {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/account_logout_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Spill */
/* ------------------------------------------ */

.icon_visible_on_L_grey_norm_48_20 {

    /* ORI > Google > Rounded > verified_user_FILL0_wght400_GRAD0_opsz48.svg */
    /* GH > Samme farge > Rund figur, uten sirkel > Ingen hover */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/visible_on_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_visible_on_L_grey_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/visible_on_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_visible_off_R_grey_norm_48_20 {

    /* ORI > Google > Rounded > verified_user_FILL0_wght400_GRAD0_opsz48.svg */
    /* GH > Samme farge > Rund figur, uten sirkel > Ingen hover */

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/visible_off_grey.svg");
    background-size:        100%;
    flex-shrink:            0;
    margin-left:            auto; /* Quickfix fordi parent strech-cls ikke fungerer */

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_visible_off_R_grey_norm_48_20:hover {

    /* Display */
    width:                  var(--lay_space_x1c25);
    height:                 var(--lay_space_x1c25);
    background:             url("../img/icon/visible_off_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Checkbox */
/* ------------------------------------------ */

.icon_checkbox_checked_blue_CR_norm_48_16 {

    /* Hack/DEV > Unchecked er samme som checked, men uten tick og med border (gjør den 1px-ish større) */
    /* ORI > icons8-tick-box.svg */
    /* GH blue_CR > No pointer */
    /* MUI > Den blå sirkelen når hoover */
    /* Alternativ: check_box_FILL0_wght400_GRAD0_opsz48_rOUNDED_REFACKED.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/checkbox_checked_blue_CR.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_checkbox_checked_blue_CR_norm_48_16:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/checkbox_checked_blue_CR_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_checkbox_checked_blue_CR_hoverless_norm_48_16 {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/checkbox_checked_blue_CR.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_checkbox_unchecked_black_norm_48_16 {

    /* Hack/DEV (se over) */
    /* GH > No pointer > Grå oldschool grey gradient -> rgb(53, 55, 60) -> rgb(66, 68, 72) -> rgb(80, 82, 86) */
    /* Alternativ: check_box_outline_blank_FILL0_wght400_GRAD0_opsz48_rOUNDED_REFACKED.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/checkbox_unchecked_black.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_checkbox_unchecked_black_norm_48_16:hover {

    /* Alternativ: check_box_outline_blank_FILL0_wght400_GRAD0_opsz48_rOUNDED_hover_REFACKED.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/checkbox_unchecked_black_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_checkbox_unchecked_black_hoverless_norm_48_16 {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/checkbox_unchecked_black.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Frame */
    border:                 var(--lay_border_main) solid var(--farge_grey_4);
    border-radius:          var(--lay_border_radius);

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Filter */
/* ------------------------------------------ */

.icon_hot_grey_norm_48_24 {

    /* ORI > Google > Rounded? > mode_heat_FILL0_wght400_GRAD0_opsz48.svg */
    /* DEV: Filter / sorting > Sjekk RED */

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/hot_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_hot_grey_norm_48_24:hover {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/hot_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_hot_grey_hoverless_norm_48_24 {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/hot_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_topp_grey_norm_48_24 {

    /* ORI: Google > Rounded > trending_up_FILL0_wght400_GRAD0_opsz48.svg */
    /* DEV: Filter / sorting > Sjekk RED */

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/topp_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_topp_grey_norm_48_24:hover {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/topp_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_topp_grey_hoverless_norm_48_24 {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/topp_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Vote */
/* ------------------------------------------ */

.icon_vote_up_grey_medium_100_32 {

    /* ORI > Wikipedia svg hack? > DEV finn link */
    /* DEV: Sjekk SO, RED for size osv. */

    /* Display */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/vote_up_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_vote_up_grey_medium_100_32:hover {

    /* Display */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/vote_up_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_vote_up_grey_hoverless_medium_100_32 {

    /* Display */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/vote_up_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_vote_down_grey_medium_100_32 {

    /* Display */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/vote_down_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_vote_down_grey_medium_100_32:hover {

    /* Display */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/vote_down_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_vote_down_grey_hoverless_medium_100_32 {

    /* Display */
    width:                  var(--lay_space_x2);
    height:                 var(--lay_space_x2);
    background:             url("../img/icon/vote_down_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Kode */
/* ------------------------------------------ */

.icon_run_beige_1_norm_24_24 {

    /* ORI > Google > Rounded? > play_circle_black_24dp.svg */

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/run_beige_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_run_beige_1_norm_24_24:hover {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/run_beige_1_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_run_green_term_norm_24_24 {

    /* ORI > Google > Rounded? > play_circle_black_24dp.svg */

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/run_green_term.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_run_green_term_norm_24_24:hover {

    /* Display */
    width:                  var(--lay_space_x1c5);
    height:                 var(--lay_space_x1c5);
    background:             url("../img/icon/run_green_term_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Fil / Folder */
/* ------------------------------------------ */

.icon_folder_closed_grey_liten_fil {

    /* ORI > Google > Rounded > folder_FILL0_wght400_GRAD0_opsz48.svg */
    /* Outlined, ikke filled */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/folder_closed_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_folder_closed_grey_liten_fil:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/folder_closed_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_folder_closed_blue_liten_fil {

    /* ORI > Google > Rounded > folder_FILL0_wght400_GRAD0_opsz48.svg */
    /* Outlined, ikke filled */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/folder_closed_blue.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_folder_open_grey_liten_fil {

    /* ORI > Google > Rounded > format_align_left_FILL0_wght400_GRAD0_opsz48 */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/folder_open_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_folder_open_grey_liten_fil {

    /* ORI > Google > Rounded > format_align_left_FILL0_wght400_GRAD0_opsz48 */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/folder_open_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}

.icon_fil_create_grey_liten_fil {

    /* ORI > Google > Rounded > draft_FILL0_wght400_GRAD0_opsz48.svg */
    /* Outlined, ikke filled */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/fil_create_grey.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}
.icon_fil_create_grey_liten_fil:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/fil_create_grey_hover.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}

.icon_fil_create_blue_liten_fil {

    /* ORI > Google > Rounded > draft_FILL0_wght400_GRAD0_opsz48.svg */
    /* Outlined, ikke filled */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/fil_create_blue.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor */
    cursor:                 pointer;
    pointer-events:         visible;
}


/* ------------------------------------------ */
/* Lang / Programmeringsspråk */
/* ------------------------------------------ */

/* .txt */
.icon_txt_liten_fil {

    /* ORI > Google > Rounded > format_align_left_FILL0_wght400_GRAD0_opsz48 */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/txt_white.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}

/* Python */
.icon_python_liten_fil {
    /* ORI > png? > 220px-Python-logo-notext.png */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/python_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_python_liten_fil:hover {
    /* ORI > png? > 220px-Python-logo-notext.png */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/python_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_python_norm_110_16 {

    /* ORI > png? > 220px-Python-logo-notext.png */
    /* Endret navn til python_1.svg i et forsøk på å løse cache-issue */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/python_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_python_norm_110_16:hover {

    /* ORI > png? > 220px-Python-logo-notext.png */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/python_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_python_norm_tittel {

    /* ORI > png? > 220px-Python-logo-notext.png */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/python_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_python_norm_tittel:hover {

    /* ORI > png? > 220px-Python-logo-notext.png */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/python_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_python_stor_forside {
    /* ORI > png? > 220px-Python-logo-notext.png */

    /* Display */
    width:                  0px; /* Responsive */
    height:                 0px; /* Responsive */
    background:             url("../img/icon/python_1.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}

/* JavaScript */
.icon_javascript_liten_fil {
    /* ORI > https://commons.wikimedia.org/wiki/File:Unofficial_JavaScript_logo_2.svg */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/javascript_basic.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_javascript_norm_tittel {
    /* ORI > Basic > https://commons.wikimedia.org/wiki/File:Unofficial_JavaScript_logo_2.svg */

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/javascript_basic.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_javascript_stor_forside {
    /* ORI > Forside > https://cdnlogo.com/logo/javascript_70428.html */

    /* Display */
    width:                  0px; /* Responsive */
    height:                 0px; /* Responsive */
    background:             url("../img/icon/javascript_forside.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}

/* HTML */
.icon_html_liten_fil {

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/html.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_html_liten_fil:hover {

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/html.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_html_norm_512_12_SIZE {
    /* Ubrukt > Kun for size */
    /* ORI: */
    /* https://commons.wikimedia.org/wiki/File:HTML5_logo_and_wordmark.svg */
    /* https://commons.wikimedia.org/wiki/File:HTML5_Badge.svg */
    /* https://commons.wikimedia.org/wiki/File:HTML5_Badge.svg#/media/File:HTML5_Badge.svg */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/html.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_html_norm_tittel {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/html.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_html_norm_tittel:hover {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/html.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_html_stor_forside {

    /* Display */
    width:                  0px; /* Responsive */
    height:                 0px; /* Responsive */
    background:             url("../img/icon/html.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */

}

/* CSS */
.icon_css_liten_fil {

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/css.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_css_norm_512_12_SIZE {
    /* ORI: */
    /* https://commons.wikimedia.org/wiki/File:CSS3_logo_and_wordmark.svg */
    /* https://commons.wikimedia.org/wiki/File:CSS3_logo_and_wordmark.svg#/media/File:CSS3_logo.svg */

    /* Display */
    width:                  var(--lay_space_x075);
    height:                 var(--lay_space_x075);
    background:             url("../img/icon/html.svg");
    background-size:        100%;
    flex-shrink:            0;
}
.icon_css_norm_tittel {

    /* Display */
    width:                  var(--lay_space_x1);
    height:                 var(--lay_space_x1);
    background:             url("../img/icon/css.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}
.icon_css_stor_forside {

    /* Display */
    width:                  var(--lay_icon_forside_1_xx);
    height:                 var(--lay_icon_forside_1_yy);
    background:             url("../img/icon/css.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}

/* JSON > DEV > Hardkodet sizes */
.icon_json_liten_fil {

    /* Display */
    width:                  0.8rem;
    height:                 1.1rem;
    background:             url("../img/icon/json.svg");
    background-size:        100%;
    flex-shrink:            0;

    /* Cursor
    - DEV: Lag link
    cursor: pointer;
    pointer-events: visible; */
}

/* ------------------------------------------ */
/* Reponsive > Søkeord > CSS_Media */
/* ------------------------------------------ */

/* Mobile 2 */
@media (min-width: 0px) {

    .icon_python_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_2_xx);
        height:                 var(--lay_icon_forside_2_yy);

    }

    .icon_javascript_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_2_xx_js);
        height:                 var(--lay_icon_forside_2_yy_js);

    }

    .icon_html_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_2_xx);
        height:                 var(--lay_icon_forside_2_yy);
    }

    .icon_css_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_2_xx);
        height:                 var(--lay_icon_forside_2_yy);
    }

}

/* Mobile 1, Desktop */
@media (min-width: 530px) {

    .icon_python_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_1_xx);
        height:                 var(--lay_icon_forside_1_yy);

    }

    .icon_javascript_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_1_xx_js);
        height:                 var(--lay_icon_forside_1_yy_js);

    }

    .icon_html_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_1_xx);
        height:                 var(--lay_icon_forside_1_yy);
    }

    .icon_css_stor_forside {

        /* Display */
        width:                  var(--lay_icon_forside_1_xx);
        height:                 var(--lay_icon_forside_1_yy);
    }

}


/* ------------------------------------------ */
/* End */
/* ------------------------------------------ */
